import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import {
    lightningChart,
    AxisTickStrategies,
    Themes,
    SolidFill,
    ColorHEX,
    SolidLine,
    emptyTick,
    RectangleFigure,
    RectangleSeries,
    ChartXY,
    CursorFormatterSeriesOverride,
    SolveResultRectangleSeries,
    ResultTableCellContent,
    emptyFill
} from '@lightningchart/lcjs';
import { environment } from 'app/environments/environment';
import { BAR_GRAPH_BLOCKAGE_THRESHOLD, BAR_GRAPH_LINE_THICKNESS, BAR_GRAPH_LOWER_REC_END_Y, BAR_GRAPH_LOWER_REC_START_Y, BAR_GRAPH_MENU_INITIAL_POSITION, BAR_GRAPH_MENU_POSITION_X, BAR_GRAPH_MENU_POSITION_Y, BAR_GRAPH_MID_LINE_Z_INDEX, BAR_GRAPH_ML_SERIES_NAME, BAR_GRAPH_Y_AXIS_INTERVAL_MAX, BAR_GRAPH_Y_AXIS_INTERVAL_MIN, BAR_GRAPH_ZERO_LINE_NAME, BAR_WIDTH_VS_DAY, BLOCKAGE_SCORE_PRECISION, BlockageFlag, BP_GRAPH_ID, BP_REPORT_COLORS, BPReviewBarData, GRAPH_BLACK_COLOR, GRAPH_WHITE_COLOR_OPAQUE, GRAPH_WHITE_COLOR_TRANSPARENT, ICanUnfreeze, unfreezeGraph } from 'app/shared/models/blockage-prediction';
import { OverrideFlagOptions } from './bp-context-menu.component';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DateutilService } from 'app/shared/services/dateutil.service';

const ONE_DAY = 1000 * 60 * 60 * 24;

@Component({
    selector: 'app-bp-review-bar-graph',
    template: `<div (mouseover)="unfreeze()" #chartContainer id="chart" class="full-width" [ngClass]="{'h-400': !isDialog, 'h-300': isDialog}"></div>
    <app-bp-review-context-menu [isVisible]="showContextMenu" [position]="menuPosition" (optionSelected)="handleOptionSelected($event)"
    (closeMenu)="closeContextMenu()"></app-bp-review-context-menu>`,
    styles: [`.full-width {
        width: 100%;
    }
    .h-400 {
        height: 400px;
    }
    .h-300 {
        height: 300px;
    }`
    ]
})
export class BpReviewBarGraph implements OnInit, OnDestroy, ICanUnfreeze {
    @Input() data: BPReviewBarData[] = [];
    @Input() isDialog: boolean;
    @Output() overrideLastDay = new EventEmitter<{ date: string, flag: BlockageFlag }>()
    @ViewChild('chartContainer') chartContainer!: ElementRef;

    showContextMenu = false;
    menuPosition = BAR_GRAPH_MENU_INITIAL_POSITION;
    selectedDayBar: { lower: RectangleFigure; upper: RectangleFigure, lastDay: BPReviewBarData };
    chart: ChartXY;

    private isDarkMode: boolean;
    private subscriptions: Subscription[] = [];

    isUnfrozen: boolean = false;
    constructor(
        private statusCodeService: StatusCodeService,
        private translate: TranslateService,
        private dateutilService: DateutilService
    ) {
        document.addEventListener('click', (event) => {
            if (this.showContextMenu && !event.target['closest']('.context-menu')) {
                this.closeContextMenu();
            }
        });
    }

    ngOnInit() {
        this.subscriptions.push(
            this.statusCodeService.userInfoThemeBS.subscribe((isDarkMode: boolean) => {
                this.isDarkMode = isDarkMode;
                this.createChart();
            })
        )
    }

    private getLowerBarColor(item: BPReviewBarData): string {
        if (item.bpflag === BlockageFlag.Blockage) return BP_REPORT_COLORS.FLAG_POSITIVE;
        if (item.bpflag === BlockageFlag.NoBlockage) return BP_REPORT_COLORS.FLAG_NEGATIVE;
        if (item.bpflag !== item.bptype && item.bpflag === BlockageFlag.Unflagged) return BP_REPORT_COLORS.SCORE_LOW;

        return BP_REPORT_COLORS.SCORE_LOW;
    }

    private getUpperBarColor(item: BPReviewBarData): string {
        if (item.bpflag === BlockageFlag.Blockage) return BP_REPORT_COLORS.FLAG_POSITIVE;
        if (item.bpflag === BlockageFlag.NoBlockage) return BP_REPORT_COLORS.FLAG_NEGATIVE;
        if (item.bpflag !== item.bptype && item.bpflag === BlockageFlag.Unflagged) return BP_REPORT_COLORS.SCORE_LOW;

        return BP_REPORT_COLORS.SCORE_HIGH;
    }

    createChart() {
        if (this.chart) {
            this.chart.dispose();
        }

        this.chart = lightningChart({
            license: environment.lcLicenseNumber,
            resourcesBaseUrl: 'assets/lc',
        }).ChartXY({
            container: BP_GRAPH_ID,
            theme: this.isDarkMode ? Themes.darkGold : Themes.light,
            animationsEnabled: false
        }).setTitle('')
            .setBackgroundFillStyle(new SolidFill({ color: GRAPH_WHITE_COLOR_TRANSPARENT }))
            .setSeriesBackgroundFillStyle(new SolidFill({ color: GRAPH_WHITE_COLOR_TRANSPARENT }))
            .setAnimationsEnabled(false)

        this.chart.setMouseInteractionWheelZoom(false).setMouseInteractionRectangleZoom(false)

        const xAxis = this.chart.getDefaultAxisX()
            .setTickStrategy(AxisTickStrategies.Empty)
            .setInterval({
                start: this.formatDate(this.data[0].date).getTime(),
                end: this.formatDate(this.data[this.data.length - 1].date).getTime() + ONE_DAY
            })
            .setTitle('')
            .setAxisInteractionZoomByWheeling(false)
            .setAxisInteractionZoomByDragging(false)
            .setChartInteractionZoomByWheel(false)

        const yAxis = this.chart.getDefaultAxisY()
            .setTitle(BAR_GRAPH_ML_SERIES_NAME)
            .setInterval({ start: BAR_GRAPH_Y_AXIS_INTERVAL_MIN, end: BAR_GRAPH_Y_AXIS_INTERVAL_MAX, animate: false })
            .setTickStrategy(AxisTickStrategies.Numeric, (tickStrategy) =>
                tickStrategy.setMinorTickStyle(emptyTick)
            ).setAxisInteractionZoomByWheeling(false)
            .setAxisInteractionZoomByDragging(false)
            .setChartInteractionZoomByWheel(false)

        const horizontalLine = this.chart.addPointLineAreaSeries({})
            .setAreaFillStyle(emptyFill)
            .setName(BAR_GRAPH_ZERO_LINE_NAME)
            .setStrokeStyle(new SolidLine({
                thickness: BAR_GRAPH_LINE_THICKNESS,
                fillStyle: new SolidFill({ color: GRAPH_BLACK_COLOR })
            }));

        horizontalLine.setCursorEnabled(false)

        horizontalLine.add([
            { x: this.formatDate(this.data[0].date).getTime(), y: BAR_GRAPH_LOWER_REC_END_Y },
            { x: this.formatDate(this.data[this.data.length - 1].date).getTime() + ONE_DAY, y: BAR_GRAPH_LOWER_REC_END_Y }
        ]);

        const sharedFormatter: CursorFormatterSeriesOverride<SolveResultRectangleSeries> =
            (hit: SolveResultRectangleSeries, before: ResultTableCellContent[][]): ResultTableCellContent[][] => {
                const outTable: ResultTableCellContent[][] = [];

                const boundaries = hit.figure.getBoundaries();
                const index = this.data.findIndex(item => {
                    const dateTime = this.formatDate(item.date).getTime();
                    return boundaries.min.x >= dateTime && boundaries.max.x <= (dateTime + ONE_DAY);
                });

                if (index >= 0) {
                    const item = this.data[index];
                    const date = this.formatDate(item.date).toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                    });

                    if (hit.series === lowerBarSeries) {
                        outTable.push([this.translate.instant('COMMON.DATE_TEXT'), date]);
                    }

                    if (hit.series === upperBarSeries && item.bpscore > 0) {
                        outTable.push([this.translate.instant('COMMON.DATE_TEXT'), date]);
                        outTable.push([this.translate.instant('BLOCKAGE_PREDICT.BP_SCORE'), `${item.bpscore.toFixed(BLOCKAGE_SCORE_PRECISION)}%`]);
                    }

                    let statusText = '';
                    let statusColor = '';
                    if (item.bpflag === BlockageFlag.Blockage) {
                        statusText = this.translate.instant('BLOCKAGE_PREDICT.BLOCKAGE');
                        statusColor = BP_REPORT_COLORS.FLAG_POSITIVE;
                    } else if (item.bpflag === BlockageFlag.NoBlockage) {
                        statusText = this.translate.instant('BLOCKAGE_PREDICT.NO_BLOCKAGE');
                        statusColor = BP_REPORT_COLORS.FLAG_NEGATIVE;
                    } else {
                        statusText = this.translate.instant('BLOCKAGE_PREDICT.UNFLAGGED');
                        statusColor = BP_REPORT_COLORS.SCORE_LOW;
                    }

                    outTable.push([this.translate.instant('BLOCKAGE_PREDICT.STATUS'), statusText]);
                }

                return outTable;
            }

        const lowerBarSeries = this.chart.addRectangleSeries();
        const upperBarSeries = this.chart.addRectangleSeries();

        lowerBarSeries.setCursorFormattingOverride(sharedFormatter);
        upperBarSeries.setCursorFormattingOverride(sharedFormatter);

        this.data.forEach((item) => {
            const dateTime = this.formatDate(item.date).getTime();

            const nextDay = this.formatDate(item.date);
            nextDay.setDate(nextDay.getDate() + 1);
            const nextDateTime = nextDay.getTime();

            const dayWidth = nextDateTime - dateTime;
            const barWidth = dayWidth * BAR_WIDTH_VS_DAY;
            const barStart = dateTime + (dayWidth - barWidth) / 2;
            const barEnd = barStart + barWidth;

            const lowerRec = lowerBarSeries.add({
                x1: barStart,
                x2: barEnd,
                y1: BAR_GRAPH_LOWER_REC_START_Y,
                y2: BAR_GRAPH_LOWER_REC_END_Y
            });
            lowerRec.setFillStyle(new SolidFill({
                color: ColorHEX(this.getLowerBarColor(item))
            }));

            const lineSeries = this.chart.addPointLineAreaSeries().setStrokeStyle(new SolidLine({
                thickness: BAR_GRAPH_LINE_THICKNESS,
                fillStyle: new SolidFill({ color: GRAPH_WHITE_COLOR_OPAQUE })
            }))
                .setAreaFillStyle(emptyFill)
                .setPointFillStyle(emptyFill)
                .setPointSize(0)
                .setDrawOrder({ seriesDrawOrderIndex: BAR_GRAPH_MID_LINE_Z_INDEX });

            lineSeries.setCursorEnabled(false)
            lineSeries.add([{ x: barStart, y: BAR_GRAPH_LOWER_REC_END_Y }, { x: barEnd, y: BAR_GRAPH_LOWER_REC_END_Y }])
            let upperRec: RectangleFigure;
            if (item.bpscore > BAR_GRAPH_BLOCKAGE_THRESHOLD) {
                upperRec = upperBarSeries.add({
                    x1: barStart,
                    x2: barEnd,
                    y1: BAR_GRAPH_LOWER_REC_END_Y,
                    y2: item.bpscore / 100
                });
                upperRec.setFillStyle(new SolidFill({
                    color: ColorHEX(this.getUpperBarColor(item))
                }));

                lowerRec.onMouseClick((obj, event) => {
                    this.handleBarClick(event, obj, upperRec, item);
                })
            }

        });

        this.chart.setCursor((cursor) => cursor
            .setResultTable(table => table
                .setBackground(back => back
                    .setFillStyle(new SolidFill({ color: GRAPH_WHITE_COLOR_OPAQUE }))
                    .setStrokeStyle(new SolidLine({
                        thickness: BAR_GRAPH_LINE_THICKNESS,
                        fillStyle: new SolidFill({ color: GRAPH_WHITE_COLOR_OPAQUE })
                    }))
                )
                .setTextFillStyle(new SolidFill({ color: GRAPH_BLACK_COLOR }))
            ).setTickMarkerX((marker) => marker.dispose())
        );

    }

    closeContextMenu() {
        this.showContextMenu = false;
    }

    private handleBarClick(event: MouseEvent, lowerRec: RectangleFigure, upperRec: RectangleFigure, lastDay: BPReviewBarData) {
        event.preventDefault();
        event.stopPropagation();

        if (this.showContextMenu) {
            this.showContextMenu = false;

            return;
        }

        this.chartContainer.nativeElement.getBoundingClientRect();
        this.menuPosition = {
            x: event.clientX - (event.clientX * BAR_GRAPH_MENU_POSITION_X),
            y: event.clientY + BAR_GRAPH_MENU_POSITION_Y
        };

        this.showContextMenu = true;
        this.selectedDayBar = { lower: lowerRec, upper: upperRec, lastDay };
    }

    handleOptionSelected(option: OverrideFlagOptions) {
        if (!this.selectedDayBar) return;

        let newColor: string;
        let flagValue: BlockageFlag;

        if (option === OverrideFlagOptions.Unflagged) {
            newColor = BP_REPORT_COLORS.SCORE_LOW;
            flagValue = BlockageFlag.Unflagged;
        } else if (option === OverrideFlagOptions.Blockage) {
            newColor = BP_REPORT_COLORS.FLAG_POSITIVE;
            flagValue = BlockageFlag.Blockage;
        } else if (option === OverrideFlagOptions.NoBlockage) {
            newColor = BP_REPORT_COLORS.FLAG_NEGATIVE;
            flagValue = BlockageFlag.NoBlockage;
        }

        this.selectedDayBar.lower.setFillStyle(new SolidFill({
            color: ColorHEX(newColor)
        }));

        if (this.selectedDayBar.upper) {
            this.selectedDayBar.upper.setFillStyle(new SolidFill({
                color: ColorHEX(newColor)
            }));
        }

        this.overrideLastDay.emit({ date: this.selectedDayBar.lastDay.date, flag: flagValue });
    }

    // TODO: Temporary solution to solve graph freezing problem on initial load,
    // issue is that SOMETIMES graphs are not reflecting to any mouse interaction on initial load
    unfreeze() {
        unfreezeGraph(this, this.chartContainer);
    }

    private formatDate(date: string): Date {
        return this.dateutilService.formatDateAsTimeZone(new Date(date))
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(v => v.unsubscribe());

        this.subscriptions = [];
    }
}
