import { BasicSeriesData } from "app/shared/models/hydrographNEW";
import { LightningChartReceiver } from "../lightning-chart-object/lightning-chart-receiver";
import { Observable } from "rxjs";
import { DisplayGroupScales, UserSettings } from "app/shared/models/user-settings";
import { AnnotationSettings } from "app/shared/models/view-data";
import { ChartSerieType, HydrographBuildDataConfig, ChartPointShape } from "../hydrograph-data/hydrograph-data-model";
import { ManualScale } from "app/shared/models/hydrograph";
import { ConfirmationEntitiesEnum } from "app/shared/models/view-data-filter";
import { Color } from "@lightningchart/lcjs";

export interface LightningChartBuildDataConfig extends HydrographBuildDataConfig, LightningChartConfig {}

export interface LightningChartConfig {
    chartId?: string;
    lightningChartReceiver: LightningChartReceiver;
    seriesData$: Observable<BasicSeriesData[]>;
    userSettings?: UserSettings;
    allowEditMode?: boolean;
    annotationSettings?: AnnotationSettings;
    showEditMenu?: () => boolean;
    darkTheme: boolean;
    selectedConfirmationEntity?: ConfirmationEntitiesEnum;
    // #36798 Maintain visiblity of entity on chart - Initial series visiblity, assoc array [entityId] => isVisible
    seriesVisibility?: {[key: number]: boolean}
    customRanges?: ManualScale[];
    isMetric?: boolean;
    dataAveraging?: number;
    defaultZoom?: number[];
    startDate: Date;
    endDate: Date;
}

export enum LCValues {
    Edited = 0,
    Data = 1,
    Flagged = 2,
    Selected = 3,
    Ignored = 4
}

export interface LightningChartSerie {
    displayGroupId: number;
    entityId: number;
    entityName: string;

    seriesType: ChartSerieType;

    shape: ChartPointShape;
    yAxis: number;
    hasLegend?: boolean;
    isAnnotation?: boolean;
    data: LightningChartDataPoint[];
    color: string;

    // #37254 Show only DEPTH in Zoomband. REMOVE THIS once we want to display all entities there
    showInZoomBand: boolean;
}

export interface LightningChartDataPoint {
    x: number;
    y: number;
    color?: Color;
    value?: number;
}

export interface LegendSeriesDefinition {
    entityId: number;
    name: string;
    color: string;
    visible: boolean;
}

export interface LegendEventDefinition {
    etype: number;
    name: string;
    color: string;
    visible: boolean;
}

export interface ChangeSerieVisibility {
    entityId: number;
    name: string;
    isVisible: boolean;
}

export interface ChangeEventVisibility {
    etype: number;
    isVisible: boolean;
}
