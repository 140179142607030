import { Directive, ElementRef, AfterViewInit, OnDestroy, HostListener } from '@angular/core';

@Directive({
    selector: '[appTelInputDropdownPosition]'
})
export class TelInputDropdownPositionDirective implements AfterViewInit, OnDestroy {
    private dropdownElement: HTMLElement | null = null;
    private inputElement: HTMLElement;
    private observer: MutationObserver | null = null;

    constructor(private elementRef: ElementRef) {
        this.inputElement = this.elementRef.nativeElement;
    }

    ngAfterViewInit() {
        this.observeDropdown();
    }

    @HostListener('window:scroll', ['$event'])
    private onWindowScroll(event: Event) {
        this.setPosition();
    }

    @HostListener('window:resize', ['$event'])
    private onResize(event: Event) {
        setTimeout(() => {
            this.setPosition();
        }, 50);
    }

    private observeDropdown() {
        this.observer = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                if (mutation.type === 'attributes' && mutation.target === this.dropdownElement) {
                    if (this.dropdownElement?.classList.contains('show')) {
                        // When the dropdown is shown, reposition it
                        this.setPosition();
                    }
                }

                if (mutation.addedNodes) {
                    const dropdown = Array.from(mutation.addedNodes).find((node: HTMLElement) =>
                        node.classList?.contains('dropdown-menu') && node.classList?.contains('iti__dropdown-content')
                    ) as HTMLElement;

                    if (dropdown) {
                        this.dropdownElement = dropdown;

                        // Move the dropdown to the document body
                        document.body.appendChild(this.dropdownElement);

                        // Set initial position
                        this.setPosition();

                        // Start observing class changes on the dropdown
                        this.observeDropdownVisibility();
                    }
                }
            }
        });

        this.observer.observe(this.inputElement, {
            childList: true,
            subtree: true,
        });
    }

    private observeDropdownVisibility() {
        if (!this.dropdownElement) return;

        this.observer?.observe(this.dropdownElement, {
            attributes: true,
            attributeFilter: ['class'],
        });
    }

    private setPosition() {
        if (!this.dropdownElement || !this.inputElement) return;

        const inputRect = this.inputElement.getBoundingClientRect();
        const dropdownRect = this.dropdownElement.getBoundingClientRect();

        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        let top = inputRect.bottom;
        let left = inputRect.left;

        // Adjust top if dropdown overflows viewport
        if (top + dropdownRect.height > viewportHeight) {
            top = inputRect.top - dropdownRect.height;
        }

        // Adjust left if dropdown overflows viewport
        if (left + dropdownRect.width > viewportWidth) {
            left = viewportWidth - dropdownRect.width;
        }

        top = Math.max(0, top);
        left = Math.max(0, left);

        // Set dropdown position
        this.dropdownElement.style.position = 'absolute';
        this.dropdownElement.style.top = `${top}px`;
        this.dropdownElement.style.left = `${left}px`;
        this.dropdownElement.style.zIndex = '999999';
        this.dropdownElement.style.visibility = 'visible';
    }

    ngOnDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }

        this.dropdownElement = null;
    }
}
