import { Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Axis, AxisTickStrategies, ChartXY, ColorHEX, SeriesXY, DashedLine, DateTimeTickStrategy, emptyLine, emptyTick, lightningChart, SolidFill, SolidLine, StipplePatterns, Themes, TickStyle, SolveNearestMode, SolveResultXY, emptyFill } from '@lightningchart/lcjs';
import { environment } from 'app/environments/environment';
import { LC_MAIN_CHART_MAJOR_TICK_COLOR, LC_MAIN_CHART_MINOR_TICK_COLOR } from 'app/shared/components/hydrograph/lightning-chart-builder/lightning-chart-ui-constants';
import { BPRAIN_ENTITY, DEPTH_ENTITY, HG_COLOR_PIPE_HEIGHT, PIPE_HEIGHT, RAIN_ENTITY, RAW_VELOCITY_ENTITY, VELOCITY_ENTITY } from 'app/shared/constant';
import { BasicSeriesData, HGGraphData, DataType, AnnotationType } from 'app/shared/models/hydrographNEW';
import { BpTooltip } from '../bp-tooltip';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { BP_HYDROGRAPH_LINE_THICKNESS, BP_GRAPH_MAX_INTERVAL_OFFSET, BP_GRAPH_START_INTERVAL, BP_HYDROGRAPH_RAIN_MIN, BP_HYDROGRAPH_TICK_LINE_WIDTH, DAYS_IN_YEAR, GRAPH_WHITE_COLOR_TRANSPARENT, RAIN_ENTITY_IDS, BP_PIPE_HEIGHT_PATTERN_SCALE, ICanUnfreeze, unfreezeGraph } from 'app/shared/models/blockage-prediction';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-bp-hydro',
    template: '<div (mouseover)="unfreeze()" id="container" style="width: 100%; height: 500px;"></div>'
})
export class BpHydrographComponent implements OnInit, OnDestroy, ICanUnfreeze {
    @Input() seriesData: BasicSeriesData[] = [];
    @Input() startTs: number;
    @Input() endTs: number;

    chart: ChartXY;
    private tooltip: BpTooltip;

    private isDarkMode: boolean = false;
    private subscriptions: Subscription[] = [];

    isUnfrozen = false;
    constructor(private elementRef: ElementRef, private statusCodeService: StatusCodeService) { }

    ngOnInit() {
        this.tooltip = new BpTooltip(this.elementRef, this.seriesData, this.statusCodeService);

        this.subscriptions.push(
            this.statusCodeService.userInfoThemeBS.subscribe((isDarkMode: boolean) => {

                this.isDarkMode = isDarkMode;
                this.createChart();
            })
        )
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['seriesData'] && !changes['seriesData'].firstChange) {
            if (this.chart) {
                this.chart.dispose();
            }
            this.createChart();
        }
    }

    createChart() {
        if (this.chart) {
            this.chart.dispose();
        }
        this.chart = lightningChart({
            license: environment.lcLicenseNumber,
            resourcesBaseUrl: 'assets/lc'
        })
            .ChartXY({
                container: this.elementRef.nativeElement.querySelector('#container'),
                theme: this.isDarkMode ? Themes.darkGold : Themes.light,
                animationsEnabled: false
            }).setTitle('')
            .setBackgroundFillStyle(new SolidFill({ color: GRAPH_WHITE_COLOR_TRANSPARENT }))
            .setSeriesBackgroundFillStyle(new SolidFill({ color: GRAPH_WHITE_COLOR_TRANSPARENT }))
            .setBackgroundStrokeStyle(emptyLine)
            .setMouseInteractionRectangleFit(true)
            .setMouseInteractions(true)
            .setCursorMode(null)


        this.tooltip.setChart(this.chart);

        this.chart.onSeriesBackgroundMouseMove((chart, event) => {
            this.handleMouseMove(event);
        });

        this.chart.onSeriesBackgroundMouseLeave(() => {
            this.tooltip.hide();
        });

        const xAxis = this.chart.getDefaultAxisX()
            .setTickStrategy(AxisTickStrategies.DateTime, ticks =>
                this.applyXAxisTimezone(ticks)
                    .setGreatTickStyle(emptyTick)
                    .setMajorTickStyle((tickStyle: TickStyle) => tickStyle
                        .setGridStrokeStyle(new SolidLine({ thickness: BP_HYDROGRAPH_TICK_LINE_WIDTH, fillStyle: new SolidFill({ color: ColorHEX(LC_MAIN_CHART_MAJOR_TICK_COLOR) }) })))
                    .setMinorTickStyle((tickStyle: TickStyle) => tickStyle
                        .setGridStrokeStyle(new SolidLine({ thickness: BP_HYDROGRAPH_TICK_LINE_WIDTH, fillStyle: new SolidFill({ color: ColorHEX(LC_MAIN_CHART_MINOR_TICK_COLOR) }) })))
            ).setAnimationZoom(undefined).setAnimationScroll(false);;

        xAxis.setIntervalRestrictions((state) => ({
            startMin: this.startTs,
            endMax: this.endTs
        }));

        const depthEntity = this.seriesData.find(v => v.entityId === DEPTH_ENTITY);
        const velocityEntities = this.seriesData.filter(v => v.entityId === VELOCITY_ENTITY || v.entityId === RAW_VELOCITY_ENTITY);
        const rainEntity = this.seriesData.find(v => RAIN_ENTITY_IDS.includes(v.entityId));

        if (depthEntity && depthEntity.data && depthEntity.data.length) {
            const depthAxis = this.chart.getDefaultAxisY()
                .setTickStrategy(AxisTickStrategies.Numeric, (tickStrategy) =>
                    tickStrategy.setMinorTickStyle(emptyTick)
                );

            const label = `${depthEntity.entityName} (${depthEntity.unitOfMeasure})`;
            depthAxis.setTitle(label);

            depthAxis.setAnimationZoom(undefined).setAnimationScroll(false);
            const max = Math.max(...depthEntity.data.map(v => v.y));
            depthAxis.setInterval({ start: BP_GRAPH_START_INTERVAL, end: max * BP_GRAPH_MAX_INTERVAL_OFFSET });

            this.addLineSeries(depthAxis, depthEntity);
            this.handleAnnotations(depthAxis, depthEntity);
        }

        if (velocityEntities.some(v => v && v.data && v.data.length)) {
            const velocityAxis = this.chart.addAxisY({
                opposite: true
            }).setTickStrategy(AxisTickStrategies.Numeric, (tickStrategy) =>
                tickStrategy.setMinorTickStyle(emptyTick)
            )
                .setTitle(this.getVelocityAxisLabel(velocityEntities))

            const max = Math.max(...velocityEntities.map(v => Math.max(...v.data.map(x => x.y))));
            velocityAxis.setInterval({ start: BP_GRAPH_START_INTERVAL, end: max * BP_GRAPH_MAX_INTERVAL_OFFSET });
            velocityAxis.setIntervalRestrictions((state) => ({
                startMin: BP_GRAPH_START_INTERVAL,
                endMax: max * BP_GRAPH_MAX_INTERVAL_OFFSET
            })).setAnimationZoom(undefined).setAnimationScroll(false);

            velocityEntities.forEach(v => this.addLineSeries(velocityAxis, v));
        }

        if (rainEntity && rainEntity.data && rainEntity.data.length) {
            this.addRainSeries(this.chart.getDefaultAxisY(), rainEntity);
        }
    }

    private processData(data: HGGraphData[]) {
        return data
            .filter(point => !point.ignored)
            .map(point => ({
                x: point.x,
                y: point.correctedY ?? point.y
            }));
    }

    private applyXAxisTimezone(tickStrategy: DateTimeTickStrategy): DateTimeTickStrategy {
        const FORMAT_YMD: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
        const FORMAT_YMDHM: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'UTC' };
        const FORMAT_HM: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', timeZone: 'UTC' };

        const isYear = this.endTs - this.startTs >= DAYS_IN_YEAR;

        if (!isYear) {
            delete FORMAT_YMD.year;
            delete FORMAT_YMDHM.year;
        }

        const formatTickDate = (value: number): string => {
            const date = new Date(value);

            // #39385 if it is not the first day of the month, it will use the last day, so we add one more day to move it to the next month
            if (date.getDate() !== 1) {
                date.setDate(date.getDate() + 1);
            }

            return new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(date);
        };

        const formatMinorTickDate = (value: number): string => {
            const date = new Date(value);

            // #39385 if it is not the first day of the month, it will use the last day, so we add one more day to move it to the next month
            if (date.getDate() !== 1) {
                date.setDate(date.getDate() + 1);
            }

            return new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date) + " '" + new Intl.DateTimeFormat('en-US', { year: '2-digit' }).format(date);
        };

        return tickStrategy
            .setUTC(true)
            .setFormattingYear(
                formatTickDate,
                formatMinorTickDate,
            )
            .setFormattingMonth(undefined, FORMAT_YMD, FORMAT_YMD)
            .setFormattingWeek(undefined, FORMAT_YMD, FORMAT_YMD)
            .setFormattingDay(undefined, FORMAT_YMDHM, FORMAT_YMDHM)
            .setFormattingHour(undefined, FORMAT_HM, FORMAT_HM)
            .setFormattingMinute(undefined, FORMAT_HM, FORMAT_HM)
            .setFormattingSecond(undefined, FORMAT_HM, FORMAT_HM)
            .setFormattingMilliSecond(FORMAT_HM, FORMAT_HM);
    }

    private addRainSeries(yAxis: Axis, entity: BasicSeriesData) {
        const segmentSeries = this.chart!.addSegmentSeries({
            yAxis
        }).setName(entity.entityName)

        segmentSeries.setMouseInteractions(true)
            .onMouseMove((serie, event) => this.handleMouseMove(event))

        segmentSeries.onMouseLeave(() => this.tooltip.hide());

        const processedData = this.processData(entity.data);
        processedData.forEach(point => {
            if (point.y > BP_HYDROGRAPH_RAIN_MIN) {
                const segmentDimensions = {
                    startX: point.x,
                    startY: BP_HYDROGRAPH_RAIN_MIN,
                    endX: point.x,
                    endY: point.y
                };

                const segment = segmentSeries.add(segmentDimensions);
                segment.setStrokeStyle(
                    new SolidLine({
                        thickness: BP_HYDROGRAPH_LINE_THICKNESS,
                        fillStyle: new SolidFill({ color: ColorHEX(entity.color) })
                    })
                );
            }
        });
    }

    private addLineSeries(yAxis: Axis, entity: BasicSeriesData) {
        const lineSeries = this.chart!
            .addPointLineAreaSeries({
                yAxis
            })
            .setAreaFillStyle(emptyFill)
            .setPointFillStyle(emptyFill)
            .setPointSize(0)
            .setName(entity.entityName)
            .setStrokeStyle(stroke =>
                stroke
                    .setThickness(BP_HYDROGRAPH_LINE_THICKNESS)
                    .setFillStyle(new SolidFill({
                        color: ColorHEX(entity.color)
                    }))
            )
            .add(this.processData(entity.data));

        lineSeries.setMouseInteractions(true)
            .onMouseMove((serie, event) => this.handleMouseMove(event));

        lineSeries.onMouseLeave(() => this.tooltip.hide());
    }

    private handleAnnotations(yAxis: Axis, entity: BasicSeriesData) {
        const pipeHeightAnnotation = entity.annotations?.find(a => a.name === PIPE_HEIGHT);
        if (pipeHeightAnnotation?.data && pipeHeightAnnotation.data.length > 0) {
            const currentYMax = yAxis.getInterval().end;
            const newYMax = Math.max(...pipeHeightAnnotation.data.map(v => v.y), currentYMax) * BP_GRAPH_MAX_INTERVAL_OFFSET;

            yAxis.setInterval({ start: BP_GRAPH_START_INTERVAL, end: newYMax });
            yAxis.setIntervalRestrictions((state) => ({
                startMin: BP_GRAPH_START_INTERVAL,
                endMax: newYMax
            }));

            this.chart!
                .addPointLineAreaSeries({
                    yAxis
                })
                .setAreaFillStyle(emptyFill)
                .setPointFillStyle(emptyFill)
                .setName(pipeHeightAnnotation.name)
                .setStrokeStyle((stroke) =>
                    new DashedLine({
                        fillStyle: new SolidFill({ color: ColorHEX(HG_COLOR_PIPE_HEIGHT) }),
                        thickness: stroke.getThickness(),
                        pattern: StipplePatterns.Dashed,
                        patternScale: BP_PIPE_HEIGHT_PATTERN_SCALE,
                    }))
                .add(this.processData(pipeHeightAnnotation.data));
        }
    }

    private handleMouseMove = (event: MouseEvent) => {
        const nearestDataPoints: { point: SolveResultXY, series: SeriesXY }[] = [];
        const chartSeries = this.chart.getSeries();

        this.seriesData.forEach((item, ) => {
            const series = chartSeries.find(v => v.getName() === item.entityName);
            if (series) {
                nearestDataPoints.push({
                    point: series.solveNearest({
                        clientX: event.clientX,
                        clientY: event.clientY
                    }),
                    series
                });
            }
        });

        this.tooltip.showHydrographTooltip(event, nearestDataPoints);
    }

    private getVelocityAxisLabel(velocityEntities: BasicSeriesData[]) {
        const rawvel = velocityEntities.find(v => v.entityId === RAW_VELOCITY_ENTITY);
        const velocity = velocityEntities.find(v => v.entityId === VELOCITY_ENTITY);

        if (rawvel) {
            return `${rawvel.entityName} (${rawvel.unitOfMeasure})`;
        }

        if (velocity) {
            return `${velocity.entityName} (${velocity.unitOfMeasure})`;
        }
    }

    // TODO: Temporary solution to solve graph freezing problem on initial load,
    // issue is that SOMETIMES graphs are not reflecting to any mouse interaction on initial load
    unfreeze() {
        unfreezeGraph(this, this.elementRef);
    }

    ngOnDestroy() {
        if (this.chart) {
            this.chart.dispose();
            this.chart = undefined;
        }

        this.subscriptions.forEach(v => v.unsubscribe());
        this.subscriptions = [];

        this.tooltip?.dispose();
    }
}
