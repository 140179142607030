import { Axis, Band, ChartXY, Dashboard, LightningChart, PointLineAreaSeries, RectangleSeries, SeriesXY, SolveResultXY, UIBackground, UIElement, UITextBox, ZoomBandChart } from "@lightningchart/lcjs";
import { UserSettings } from "app/shared/models/user-settings";
import { AnnotationSettings } from "app/shared/models/view-data";
import { LightningChartTooltip } from "./lightning-chart-tooltip";
import { LightningChartEdit } from "./lightning-chart-edit";
import { BasicSeriesData } from "app/shared/models/hydrographNEW";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { LightningChartBuilder } from "../lightning-chart-builder/lightning-chart-builder";
import { DateutilService } from "app/shared/services/dateutil.service";
import { LightningChartReceiver } from "./lightning-chart-receiver";
import { RANGE_BUTTONS } from "../hydrograph-tracer-panel/hydrograph-tracer-panel.component";
import { ViewDataService } from "app/shared/services/view-data.service";
import { SeparateWindowHydrographService } from "app/shared/services/separate-window-hydrograph.service";
import { GraphAnnotationSerieConfiguration, ChartPointShape, GraphEventSerieConfiguration, GraphYAxisConfiguration } from "../hydrograph-data/hydrograph-data-model";
import { ChangeEventVisibility, ChangeSerieVisibility, LegendEventDefinition, LegendSeriesDefinition, LightningChartSerie } from "../lightning-chart-builder/lightning-chart-data-model";
import { ManualScale } from "app/shared/models/hydrograph";
import { JUMP_SCROLL_PADDING_X, JUMP_SCROLL_Y, LABEL_OVERLAP_THRESHOLD, MIN_Y_RAIN_RANGE, MIN_Y_RANGE } from "../lightning-chart-builder/lightning-chart-ui-constants";
import { debounceTime } from "rxjs/operators";
import _ from "lodash";


export interface ChartYExtreme {
    miny: number,
    maxy: number
}

export interface ChartXExtreme {
    minx: number,
    maxx: number
}

export interface LightningChartSerieDefinition {
    yAxis?: number;
    hasLegend?: boolean;
    hasTooltip?: boolean;
    precision: number;
    unitOfMeasure: string;
    shape: ChartPointShape;
    visible: boolean;
}

export class LightningChartObject {

    // #38929 GAP when max zoom out in pixels
    readonly GAP_EXTRA_ZOOM_PIXELS_LEFT = 7;
    readonly GAP_EXTRA_ZOOM_PIXELS_RIGHT = 10;

    lightningChartReceiver: LightningChartReceiver;

    builder: LightningChartBuilder;
    tooltip: LightningChartTooltip;
    edit: LightningChartEdit;

    public lightningChartHandle: LightningChart;

    public dateutilService: DateutilService;
    public viewDataService: ViewDataService;
    public separateWindowService: SeparateWindowHydrographService;

    /** Original series data, used for manipulation by edit component */
    public seriesData: BasicSeriesData[];

    public destinationDate = new Subject<Date>(); // Emit destination date change
    public editableSeriesdata: BasicSeriesData[];
    public customRanges?: ManualScale[]; // Custom Ranges

    public isMetrics: boolean;

    public eventSeries: GraphEventSerieConfiguration[];
    public eventChartSeries: { [key: number]: Band[]};

    public legendSeriesDefinition: LegendSeriesDefinition[];
    public legendEventDefinition: LegendEventDefinition[] = [];

    public dataAveraging; number;
    public isDarkTheme: boolean;
    public graphGrouping: GraphYAxisConfiguration[][];

    dashboard: Dashboard;
    ySeriesExtremes: Array<Array<ChartYExtreme>> = [];
    dataIntervalSize: number;
    charts: ChartXY[] = [];
    xExtremes: ChartXExtreme = null;
    /** Contains NOT DEFAULT axes for chart (so 2nd ones). Get y axis with this.axisYFor() */
    yAxes: Axis[] = [];
    chartSeries: SeriesXY[][] = [];
    annotationSeries: GraphAnnotationSerieConfiguration[] = [];
    seriesDef: LightningChartSerie[][] = [];
    seriesDefition: LightningChartSerieDefinition[] = [];
    zoomBandChart: ZoomBandChart;

    dragSeries: PointLineAreaSeries[][] = [];
    uiSeriesLabels: (UITextBox<UIBackground> & UIElement)[] = [];

    selectionSeries: RectangleSeries[] = [];

    chartEventSubscriptionTokens = [];

    userSettings: UserSettings;
    annotations: AnnotationSettings;

    showEditingMenu: () => boolean;

    public rainOverviewSeries: PointLineAreaSeries;

    private subscriptions: Array<Subscription> = [];
    private updateZoom$ = new BehaviorSubject<number[]>(null);

    public jumpScrollBtnLeft: UITextBox<UIBackground> & UIElement;
    public jumpScrollBtnRight: UITextBox<UIBackground> & UIElement;

    public startTime: number;
    public endTime: number;

    public constructor() {
        this.updateZoom$.pipe(debounceTime(200)).subscribe((zoom) => {
            if(this.lightningChartReceiver.updateSelectedDateRange)
                this.lightningChartReceiver.updateSelectedDateRange(zoom);
            });
    }

    public subscribeToData(observableSeriesData: Observable<BasicSeriesData[]>) {
        this.subscriptions.push(
            observableSeriesData.subscribe((res) => {

                if (!res || !res.length) return;
                this.seriesData = res;
                this.updateSeries(res);

                // #36685 #36686 build legend definition
                this.builder.buildSeriesLegendConfig(this);
                this.builder.buildEventLegendConfig(this);

                // #37329 ZoomBandChart show default range
                if(this.xExtremes && this.zoomBandChart && this.zoomBandChart.getDefaultAxisX() && this.lightningChartReceiver.endDate) {
                    const dateEndOffset = new Date(this.lightningChartReceiver.endDate).getTimezoneOffset() * 60000;
                    const endTime = this.lightningChartReceiver.endDate.getTime() - dateEndOffset;

                    this.zoomBandChart.getDefaultAxisX().setInterval({start: this.xExtremes.minx, end: endTime});
                }
            })
        );
    }

    public onEntityChanged() {
        this.edit?.onEntityChanged();
    }

    public seriePlacement(serieDef: {yAxis: number}): {chartIndex: number, yAxisIndex: number} {
        if(this.graphGrouping) {
            let yCnt = 0;
            for(let i = 0; i < this.graphGrouping.length; i++) {
                const len = this.graphGrouping[i].length;

                if(serieDef.yAxis === yCnt) {
                    return {chartIndex: i, yAxisIndex: 0};
                } else if(len > 1 && serieDef.yAxis === yCnt + 1) {
                    return {chartIndex: i, yAxisIndex: 1};
                }

                yCnt += len;
            }
        } else {
            const chartIndex = Math.floor(serieDef.yAxis / 2);
            const yAxisIndex = serieDef.yAxis % 2;

            return {chartIndex, yAxisIndex};
        }
    }

    public updateChartLabels(start: number, end: number, yAxis: Axis) {
        if (start < 0) return;


        const xRange = end - start;
        const labelSpacing = xRange * LABEL_OVERLAP_THRESHOLD;
        const yRange = Math.abs(yAxis.getInterval().end - yAxis.getInterval().start);
        const yThresholdPercentage = LABEL_OVERLAP_THRESHOLD;
        const yThreshold = yThresholdPercentage * yRange;

        // Dictionary to keep track of label positions
        const labelPositions = new Map<number, { x: number, y: number }>();

        const adjustLabelPosition = (label, labelIndex) => {
            let newX = end;
            const currentY = label.getPosition().y;

            const isOverlapping = (x, y, currentIndex) => {
                for (const [index, pos] of labelPositions.entries()) {
                    if (index === currentIndex) continue; // Skip self comparison

                    const { x: posX, y: posY } = pos;
                    const xDifference = Math.abs(x - posX);
                    const yDifference = Math.abs(y - posY);

                    if (xDifference < labelSpacing && yDifference < yThreshold) {
                        return true;
                    }
                }
                return false;
            };

            while (isOverlapping(newX, currentY, labelIndex)) {
                newX -= labelSpacing;
            }

            // Set the adjusted position
            label.setPosition({
                x: newX,
                y: currentY
            });

            // Update the dictionary with the new position
            labelPositions.set(labelIndex, { x: newX, y: currentY });
        };

        // Initial setting of positions and adding them to the dictionary
        for (let i = 0; i < this.uiSeriesLabels.length; i++) {
            const label = this.uiSeriesLabels[i];
            const initialX = end;
            const initialY = label.getPosition().y;

            // Add initial position to dictionary
            labelPositions.set(i, { x: initialX, y: initialY });

            // Adjust position only if there are more than one labels
            if (this.uiSeriesLabels.length > 1) {
                adjustLabelPosition(label, i);
            } else {
                // Set the position directly to end if it's the only label
                label.setPosition({
                    x: end,
                    y: initialY
                });
            }
        }
    }

    public installxAxisOnIntervalChange(xAxis: Axis, yAxis: Axis, index: number) {
        const self = this;

        xAxis.onIntervalChange((axis, start, end) => {
            if(!this.xExtremes) return;

            if(index === 0) {
                // Notify SG selection. Do only for first chart.
                if(this.lightningChartReceiver?.updateSelectedDateRange) {
                    // #38929 Threat extended zoom as max zoom
                    if(this.isExtraZoom()) {
                        self.updateZoom$.next([this.startTime, this.endTime]);
                    } else {
                        self.updateZoom$.next([start, end]);
                    }
                }
            }

            self.updateChartLabels(start, end, yAxis);
            self.checkJumpScroll();
        });
    }

    handleTooltip(chart: ChartXY, lcChart: LightningChartObject, event: MouseEvent) {
        const nearestDataPoints: SolveResultXY[][] = [];

        const mouseLocationAxis = chart.translateCoordinate(event, chart.coordsAxis);

        if(mouseLocationAxis.x < lcChart.xExtremes?.minx) {
            mouseLocationAxis.x = lcChart.xExtremes?.minx;
        }
        if(mouseLocationAxis.x > lcChart.xExtremes?.maxx) {
            mouseLocationAxis.x = lcChart.xExtremes?.maxx;
        }

        const translatedPoint = chart.translateCoordinate(mouseLocationAxis, chart.coordsAxis, chart.coordsClient);

        const mousePoint = {
            clientX: translatedPoint.clientX,
            clientY: event.clientY
        }


        for(let c = 0; c < this.chartSeries.length; c++) {
            nearestDataPoints[c] = this.chartSeries[c]?.map((el, index) => {
                const point = el.solveNearest(event);

                // #43810 Only for annotations (update 5.x to 6.x)
                // We always want to see annotation value in tooltip.
                // So in 5.x solveNearest worked better. In 6.x it does not so good.
                if(!point && this.isAnnotation(c, index)) {
                    const serieCoords = chart.translateCoordinate(event, { x: el.axisX, y: el.axisY });
                    const annotationSerieDef = this.getAnnotationDef(c, index);

                    const pointIndex = _.sortedIndexBy(annotationSerieDef.data, {
                        x: serieCoords.x,
                        y: null
                    }, 'x');

                    if(pointIndex) {
                        const point = annotationSerieDef.data[pointIndex];
                        return {
                            cursorPosition: null,
                            axisX: el.axisX,
                            axisY: el.axisY,
                            series: el,
                            x: point.x,
                            y: point.y
                        };
                    }
                }

                return point;
            });
        }

        lcChart.tooltip?.showCursorAt(chart, mousePoint, nearestDataPoints)
    }

    createMouseMoveHandler(chart: ChartXY, lcChart: LightningChartObject) {
        return (_, event: MouseEvent) => {
            this.handleTooltip(chart, lcChart, event);
        };
    }

    public installChartEvents(chart: ChartXY, index: number) {
        chart.onSeriesBackgroundMouseMove(this.createMouseMoveHandler(chart, this));

        chart.onSeriesBackgroundMouseLeave((_, ignoreEvent) => {
            this.viewDataService.highlightScatterPoint.next(null);
            this.tooltip?.tooltipHide();
        });

        chart.onBackgroundMouseDoubleClick((chart, event) => {
            this.edit?.mouseDoubleClick(event);
        });
        chart.onSeriesBackgroundMouseDoubleClick((chart, event) => {
            this.edit?.mouseDoubleClick(event);
        });

        if(!this.chartEventSubscriptionTokens[index]) this.chartEventSubscriptionTokens[index] = [];

        this.chartEventSubscriptionTokens.push(chart.onSeriesBackgroundMouseDrag((_, event, button, startLocation) => {
            // #38631 Let tooltip update during drag (selection)
            this.handleTooltip(chart, this, event);

            if(!this.showEditingMenu()) return;
            this.edit?.selectAreaOnChartTick(index, event, button, startLocation);
        }));

        this.chartEventSubscriptionTokens.push(chart.onSeriesBackgroundMouseDragStop((_, event, button, startLocation) => {
            if(!this.showEditingMenu()) return;
            this.edit?.selectAreaOnChartEnd(index, event, button, startLocation);
        }));
    }

    ////////////////////////////////////////////////////////////////////

    public isAnnotation(chartIndex: number, seriesIndex: number) {
        return !!!this.seriesDef[chartIndex][seriesIndex];
    }

    public getAnnotationDef(chartIndex: number, seriesIndex: number) {
        if(!this.isAnnotation(chartIndex, seriesIndex)) return;

        return this.annotationSeries[seriesIndex - this.seriesDef[chartIndex].length];
    }

    public getXYaxisForEntity(entityName: string): { x: Axis, y: Axis } {
        let series: SeriesXY;

        this.chartSeries.forEach((chartSeries: SeriesXY[]) => {
            const s = chartSeries.find(v => v.getName() === entityName);

            if (s) {
                series = s;
            }
        });

        if (!series) return null;

        return { x: series.axisX, y: series.axisY };
    }

    public chartForSelectedEntity(selectedEntity: number, name?: string): {chartIndex: number, serieIndex: number} {

        for(let c = 0; c < this.seriesDef.length; c++) {
            for(let i = 0; i < this.seriesDef[c]?.length ?? 0; i++) {
                if(this.seriesDef[c][i].entityId !== selectedEntity) continue;

                if(name && this.seriesDef[c][i].entityName !== name) continue;

                return {
                    chartIndex: c,
                    serieIndex: i
                }
            }
        }

        return {
            chartIndex: null,
            serieIndex: null
        };
    }

    public getExtremesForYAxis(chartIndex: number, yAxisIndex: number, isRain = false): {min: number, max: number} {
        const otherYAxisIndex = yAxisIndex === 0 ? 1 : 0;

        let min = Number.MAX_SAFE_INTEGER;
        let max = Number.MIN_SAFE_INTEGER;

        let otherMin = Number.MAX_SAFE_INTEGER;
        let otherMax = Number.MIN_SAFE_INTEGER;

        if(this.ySeriesExtremes[chartIndex][yAxisIndex] && min > this.ySeriesExtremes[chartIndex][yAxisIndex].miny) min = this.ySeriesExtremes[chartIndex][yAxisIndex].miny;
        if(this.ySeriesExtremes[chartIndex][yAxisIndex] && max < this.ySeriesExtremes[chartIndex][yAxisIndex].maxy) max = this.ySeriesExtremes[chartIndex][yAxisIndex].maxy;

        if(this.ySeriesExtremes[chartIndex][otherYAxisIndex] && otherMin > this.ySeriesExtremes[chartIndex][otherYAxisIndex].miny) otherMin = this.ySeriesExtremes[chartIndex][otherYAxisIndex].miny;
        if(this.ySeriesExtremes[chartIndex][otherYAxisIndex] && otherMax < this.ySeriesExtremes[chartIndex][otherYAxisIndex].maxy) otherMax = this.ySeriesExtremes[chartIndex][otherYAxisIndex].maxy;

        if(otherMin < 0) {
            const otherMinus = Math.abs(otherMin);
            const otherPlus = otherMax;

            const curPlus = max;

            const newMinusValue = curPlus * (otherMinus / otherPlus)

            min = -newMinusValue;
        }

        if(isRain) {
            if(Math.abs(max - min) < MIN_Y_RAIN_RANGE) max = min + MIN_Y_RAIN_RANGE;
        } else {
            if(Math.abs(max - min) < MIN_Y_RANGE) max = min + MIN_Y_RANGE;
        }

        return {
            min: min,
            max: max
        }
    }

    public customRangesForDisplayGroupId(aDisplayGroupId: number): {min?: number, max?: number, autoOpt?: boolean} {
        if(!this.customRanges) return {min: undefined, max: undefined};
        for(const customRange of this.customRanges) {

            if(aDisplayGroupId !== customRange.displayGroupId) continue;

            const start = customRange.min;
            const end = customRange.max;
            return {
                min: start === null || start === undefined ? start : start,
                max: end === null || end === undefined ? end : end,
                autoOpt: customRange.autoOpt
            };
        }
        return {min: undefined, max: undefined, autoOpt: undefined};
    }


    public customRangesForEntity(aEntityId: number): {min?: number, max?: number, autoOpt?: boolean} {
        if(!this.customRanges) return {min: undefined, max: undefined};
        for(const customRange of this.customRanges) {

            for(const entityId of customRange.entities) {
                if(aEntityId !== entityId) continue;

                const start = customRange.min;
                const end = customRange.max;
                return {
                    min: start === null || start === undefined ? start : start,
                    max: end === null || end === undefined ? end : end,
                    autoOpt: customRange.autoOpt
                };
            }
        }
        return {min: undefined, max: undefined, autoOpt: undefined};
    }

    public customRangesForYAxis(aChartIndex: number, aSerieIndex: number): {min?: number, max?: number} {
        for(const customRange of this.customRanges) {
            const start = customRange.min;
            const end = customRange.max;

            for(const entityId of customRange.entities) {
                const {chartIndex, serieIndex} = this.chartForSelectedEntity(entityId);
                if(chartIndex !== aChartIndex || serieIndex !== aSerieIndex) continue;
                const serieDef = this.seriesDef[chartIndex][serieIndex];
                const placement = this.seriePlacement(serieDef);

                return {
                    min: start === null || start === undefined ? this.ySeriesExtremes[chartIndex][placement.yAxisIndex].miny : start,
                    max: end === null || end === undefined ? this.ySeriesExtremes[chartIndex][placement.yAxisIndex].maxy : end
                };
            }
        }
        return {min: undefined, max: undefined};
    }

    public disposeCharts() {
        this.clearChart();
        this.tooltip?.dispose();

        for(let i = 0; i < this.charts.length; i++) {
            const chart = this.charts[i];
            chart.offSeriesBackgroundMouseDrag(this.chartEventSubscriptionTokens[i][0]);
            chart.offSeriesBackgroundMouseDragStop(this.chartEventSubscriptionTokens[i][1]);
        }
        for(const chart of this.charts) {
            chart.dispose();
        }
        this.chartEventSubscriptionTokens = [];
        if(this.zoomBandChart) this.zoomBandChart.dispose();
        this.charts = [];
        this.yAxes = [];
    }
    public clearChart() {
        for(let ci = 0; ci < this.chartSeries.length; ci++) {
            if(this.chartSeries[ci]) {
                for(const s of this.chartSeries[ci]) {
                    s.dispose();
                }
            }
        }
        this.chartSeries = [];
        this.seriesDef = [];
        this.ySeriesExtremes = [];
        this.xExtremes = null;
    }

    public axisYFor(chartIndex: number, yAxisIndex: number) {
        const chart = this.charts[chartIndex];
        if(!chart) return null;
        return yAxisIndex === 0 ? chart?.getDefaultAxisY() : this.yAxes[chartIndex];
    }

    /** #38929 Whenver chart zoom is extra zoom (so outisde date range) */
    public isExtraZoom() {
        if(!this.charts || this.charts.length < 1) return false;

        const interval = this.charts[0].getDefaultAxisX()?.getInterval();

        return interval && interval.start < this.startTime;
    }

    /** #38929 Add some space in pixels for extra zoom */
    public getExtraZoomInterval() {
        const boundingClientRect = this.dashboard.engine.container.getBoundingClientRect();
        const widthInPixels = boundingClientRect.width;

        const widthInMs = this.endTime - this.startTime;

        const MsToPixelsProportion = widthInMs / widthInPixels;

        const gapLeft = this.GAP_EXTRA_ZOOM_PIXELS_LEFT * MsToPixelsProportion;
        const gapRight = this.GAP_EXTRA_ZOOM_PIXELS_RIGHT * MsToPixelsProportion;

        return {
            start: this.startTime - gapLeft,
            end: this.endTime + gapRight
        };
    }

    public updateSeries(seriesData: BasicSeriesData[]) {
        const chart = this.charts[0];
        let interval = null;

        if(chart) {
            interval = chart.getDefaultAxisX().getInterval();

            // #36798 Maintain visiblity of entity on chart
            for(let cIndex = 0; cIndex < this.chartSeries.length; cIndex++) {
                const chartSeries = this.chartSeries[cIndex];

                if(!chartSeries) continue;

                for(let sIndex = 0; sIndex < chartSeries.length; sIndex++) {
                    const series = chartSeries[sIndex];
                    const seriesDef = this.seriesDef[cIndex][sIndex];

                    if(seriesDef) {
                        this.seriesDefition[seriesDef.entityId].visible = series.getVisible();
                    }
                }
            }

        }

        this.clearChart();

        this.builder.createSeries(this, seriesData);
        this.builder.createAnnotationSeries(this, this.annotationSeries);
        this.detectChanges();

        if(chart) {
            if(interval.start <= 0) {
                const extraZoomInterval = this.getExtraZoomInterval();
                chart.getDefaultAxisX().setInterval({start: extraZoomInterval.start, end: extraZoomInterval.end, stopAxisAfter: false});
                interval.start = extraZoomInterval.start;
                interval.end = extraZoomInterval.end;
            }

            // #37895 TODO: REMOVE SETTIMEOUT - Workaround for not displaying full range
            setTimeout(() => {
                if (chart && chart.getDefaultAxisX() && interval.start <= 0) {
                    chart?.getDefaultAxisX()?.setInterval({start: interval.start, end: interval.end, stopAxisAfter: false});
                }
            }, 10);
        } else {
            this.builder.setDefaultIntervals(this);
        }

    }

    public detectChanges: () => void = undefined;

    private clickZoomButton(buttonId: RANGE_BUTTONS) {
        if(!this.charts[0]) return;

        let min = this.xExtremes.minx;
        let max = this.xExtremes.maxx;
        const oneDay = 60 * 60 * 24 * 1000;
        const daysInMonth = 31;

        const minDate = new Date(min);
        const numberOfDaysForCurrentMonth = new Date(minDate.getFullYear(), minDate.getMonth() + 1, 0).getDate();

        switch (buttonId) {
            case 0: {
                // 1 day
                max = min + oneDay;
                break;
            }
            case 1: {
                // 1 week
                max = min + oneDay * 7;
                break;
            }
            case 2: {
                // 1 month
                max = min + oneDay * daysInMonth;
                break;
            }
            case 3:
                // #38929 Threat extended zoom as max zoom
                const extraZoomInterval = this.getExtraZoomInterval();
                min = extraZoomInterval.start;
                max = extraZoomInterval.end;
            default:
                break;
        }

        const chart = this.charts[0];

        // #37895 TODO: REMOVE SETTIMEOUT - Workaround for not displaying full range
        setTimeout(() => {
            chart.getDefaultAxisX()?.setInterval({start: min, end: max, stopAxisAfter: false});
        }, 10);

        if(this.lightningChartReceiver && this.lightningChartReceiver.updateSelectedDateRange) {
            this.lightningChartReceiver.updateSelectedDateRange([min, max]);
        }
    }

    // do not remove, it is called from HTML template
    public zoomButtonClicked(buttonId: RANGE_BUTTONS) {
        this.clickZoomButton(buttonId);
        // TODO: Unselect if range is not any of ones inside RANGE_BUTTONS
    }

    public tracerButtonClicked(isStatic: boolean) {
        if(this.tooltip) {
            this.tooltip.itTooltipStatic = isStatic;
        }
    }

    public customRangesChange(customRanges: ManualScale[]) {
        for(const customRange of customRanges) {
            const start = customRange.min;
            const end = customRange.max;

            for(const entityId of customRange.entities) {
                const {chartIndex, serieIndex} = this.chartForSelectedEntity(entityId);
                const serieDef = this.seriesDef[chartIndex][serieIndex];
                const placement = this.seriePlacement(serieDef);

                const entityYAxis = this.axisYFor(chartIndex, serieIndex);

                entityYAxis.setInterval({
                    start: start === null || start === undefined ? this.ySeriesExtremes[chartIndex][placement.yAxisIndex].miny : start,
                    end: end === null || end === undefined ? this.ySeriesExtremes[chartIndex][placement.yAxisIndex].maxy : end
                });
            }
        }
    }

    public updateSeriesVisibility(entityDef: ChangeSerieVisibility) {
        this.seriesDefition[entityDef.entityId].visible = entityDef.isVisible;
        const {chartIndex, serieIndex} = this.chartForSelectedEntity(entityDef.entityId, entityDef.name);

        this.chartSeries[chartIndex][serieIndex].setVisible(entityDef.isVisible);
    }

    public updateEventVisibility(entityDef: ChangeEventVisibility) {
        if(!this.eventChartSeries[entityDef.etype]) return;

        for(const band of this.eventChartSeries[entityDef.etype]) {
            band.setVisible(entityDef.isVisible);
        }
    }

    private checkJumpScroll() {
        const chart = this.charts[0];
        const curInterval = chart.getDefaultAxisX().getInterval();
        const min = this.xExtremes.minx;
        const max = this.lightningChartReceiver.endDate?.getTime();

        this.builder.enableJumpScroll(this.jumpScrollBtnLeft, curInterval.start !== min, this.isDarkTheme);
        this.builder.enableJumpScroll(this.jumpScrollBtnRight, curInterval.end !== max, this.isDarkTheme);
    }

    private applyJumpScrollInterval(modifier: -1 | 1) {
        const chart = this.charts[0];
        const curInterval = chart.getDefaultAxisX().getInterval();
        const diff = modifier * (curInterval.end - curInterval.start);
        const absDiff = Math.abs(diff);
        const min = this.xExtremes.minx;
        const max = this.lightningChartReceiver.endDate?.getTime();

        const interval = {start: curInterval.start + diff, end: curInterval.end + diff};

        if(interval.start < min)  {
            interval.start = min;
            interval.end = min + absDiff;
        }
        if(interval.end > max)  {
            interval.end = max;
            interval.start = max - absDiff;
        }
        chart.getDefaultAxisX().setInterval({start: interval.start, end: interval.end, stopAxisAfter: false});
        this.checkJumpScroll();
    }

    public installJumpScrollListeners() {
        this.jumpScrollBtnLeft.onMouseClick((obj, event) => {
            this.applyJumpScrollInterval(-1);
        });

        this.jumpScrollBtnRight.onMouseClick((obj, event) => {
            this.applyJumpScrollInterval(1);
        });
    }

    public installOnResize() {
        this.dashboard?.onResize((obj, width, height) => {

            this.jumpScrollBtnLeft.setPosition({x: JUMP_SCROLL_PADDING_X, y: JUMP_SCROLL_Y})
            this.jumpScrollBtnRight.setPosition({x: width - JUMP_SCROLL_PADDING_X, y: JUMP_SCROLL_Y})
        })
    }

    public currentInterval() {
        return this.charts[0]?.getDefaultAxisX()?.getInterval();
    }

    destroy() {
        if(this.tooltip) {
            this.tooltip.dispose();
        }
        for(const chart of this.charts) {
            chart.dispose();
        }
        this.charts = [];

        this.seriesDefition = [];
        this.seriesDef = [];

        if(this.dashboard) this.dashboard.dispose();
        this.dashboard = null;

        this.updateZoom$.unsubscribe();
        for(const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
        this.subscriptions = [];

        this.lightningChartHandle?.dispose();
        this.lightningChartHandle = null;
    }

}
