import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Customer } from 'app/shared/models/customer';
import { CustomerService } from 'app/shared/services/customer.service';
import { map, Observable, Subscription } from 'rxjs';
import { BlockagePredictService } from '../blockage-predict.service';
import { EXPORT_TYPE_COLLECTION, LocationGroupSelectable, RainfallSearchTypeValue } from 'app/pages/report/site-commentary-report/site-commentary.model';
import { Selectable } from 'app/shared/models/selectable';
import { FormControl } from '@angular/forms';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { LocationGroup } from 'app/shared/models/location-group';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UsersService } from 'app/pages/admin/users.service';
import { LocationArgs, Locations } from 'app/shared/models/locations';
import { ADMIN_DATE_FORMAT, DAYS_TO_PLOT_DEFAULT, HumanReviewOverview, OverviewArgs, SCORE_ABOVE_DEFAULT } from 'app/shared/models/blockage-prediction';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { MonitorSeriesNames } from 'app/shared/components/location-card/location-card.constants';

const ALL_CUSTOMERS: Customer = {
    customerID: 0,
    customerName: 'ALL CUSTOMERS',
};

@Component({
    selector: 'app-bp-human-review',
    templateUrl: './human-review-container.component.html',
    styleUrls: ['./human-review-container.component.scss'],
})
export class HumanReviewComponent implements OnInit {
    isLoading: boolean = false;
    customers$: Observable<Customer[]>;

    bpDateFormat: string = ADMIN_DATE_FORMAT;
    reportDate: Date = new Date(new Date().setDate(new Date().getDate() - 1));
    scoreAbove: number | string = SCORE_ABOVE_DEFAULT * 100;     // convert to percentage
    daysToPlot: number = DAYS_TO_PLOT_DEFAULT;

    overviewReport: HumanReviewOverview;

    blockagesToggle: boolean = false;
    unflaggedToggle: boolean = false;
    nonBlockageToggle: boolean = false;
    invertScatterToggle: boolean = false;

    selectedCustomerId: number = 0;
    selectedLocationIds: number[] = [];
    selectedLocationGroupId: number = 0;

    locationTypeCollection: Array<{ name: string, value: string }> = EXPORT_TYPE_COLLECTION;
    locationGroupList: Array<LocationGroupSelectable> = [];
    filteredLocations: Selectable[] = [];
    locations: Selectable[] = [];
    locationType = RainfallSearchTypeValue.location;
    shouldIncludeInactiveLocations = false;
    selectAllLocations: boolean;

    locationSearch = new FormControl('');
    today: Date = new Date();

    locationGroupsUnavailable: boolean;
    locationsUnavailable: boolean;
    minimumLocationError: boolean;

    private subscriptions: Subscription[] = [];
    constructor(
        private customerService: CustomerService,
        private blockagePredictService: BlockagePredictService,
        private locationGroupService: LocationGroupService,
        private usersService: UsersService,
        public dateutilsService: DateutilService
    ) { }

    ngOnInit(): void {
        this.customers$ = this.customerService.customers.pipe(
            map((customers: Customer[]) => [ALL_CUSTOMERS, ...customers])
        );

        this.subscriptions.push(this.locationSearch.valueChanges.subscribe((searchValue: string) => this.filterLocations(searchValue)));
        this.formatScoreAbove();
    }

    onChangeCustomer() {
        this.getLocations();
        this.getLocationGroups();
    }

    setDate(event) {
        if (!event || !event.value) {
            return;
        }

        const newDate = new Date(event.value);

        if (newDate.getFullYear() === this.reportDate.getFullYear() &&
            newDate.getMonth() === this.reportDate.getMonth() &&
            newDate.getDate() === this.reportDate.getDate()) {
            return; // Dates are the same, do nothing
        }

        this.reportDate = newDate;
    }

    handleLocationTypeChange(event: MatSelectChange) {
        if (event.value === RainfallSearchTypeValue.locGroup) {
            this.locationsUnavailable = false;
            this.locationGroupList = this.locationGroupList.map(v => ({ ...v, isChecked: false }));
        }
    }

    handleSelectedLocationsGroup(event: LocationGroupSelectable[]) {
        if (!event || !event.length) return;

        this.selectedLocationGroupId = event[0].id;
        this.selectedLocationIds = event[0].locations.map(v => v.id);
    }

    onLocationChecked() {
        this.selectedLocationIds = this.locations.filter(v => v.isChecked).map(v => v.id);

        if (!this.selectedLocationIds.length) {
            this.minimumLocationError = true;

            return;
        }
        this.minimumLocationError = false;
    }

    onSelectAllLocations(event: MatCheckboxChange) {
        const currentValue = event.checked;
        this.selectAllLocations = currentValue;

        if (!this.selectAllLocations) {
            this.minimumLocationError = true;
        }

        this.minimumLocationError = false;
        this.filteredLocations.forEach(v => v.isChecked = currentValue);
        this.onLocationChecked();
    }

    getOverviewReport() {
        this.isLoading = true;

        this.overviewReport = null;
        const args: OverviewArgs = {
            blockages: this.blockagesToggle,
            nonBlockages: this.nonBlockageToggle,
            unflagged: this.unflaggedToggle,
            bpscore: Number(this.scoreAbove) / 100,     // convert from percentage
            details: true,
            bpdate: this.dateutilsService.formatDateToBeginningOfDay(this.reportDate)
        }

        if (this.selectedCustomerId !== 0) {
            args.cid = this.selectedCustomerId;

            if (this.selectedLocationIds.length) {
                args.lids = this.selectedLocationIds;
            }
        }

        this.blockagePredictService.getHumanReview(args).subscribe({
            next: data => {
                this.isLoading = false;

                this.overviewReport = data;
            },
            error: () => this.isLoading = false
        })
    }

    formatScoreAbove() {
        if (this.scoreAbove !== null && this.scoreAbove !== undefined) {
            this.scoreAbove = Number(this.scoreAbove).toFixed(2);
        }
    }

    public getLocationGroups() {
        this.locationGroupList = [];

        if (this.selectedCustomerId === 0) {
            return;
        }

        const locationGroupSubscription = this.locationGroupService.getLocationGroups(this.selectedCustomerId).subscribe(
            (locationGroupList) => {
                if (locationGroupList && locationGroupList.locationGroups && locationGroupList.locationGroups.length) {
                    this.locationGroupsUnavailable = false;
                    locationGroupList.locationGroups.forEach((locationGroup: LocationGroup) => {
                        this.locationGroupList.push({
                            id: locationGroup.locationGroupID,
                            name: locationGroup.name,
                            isChecked: false,
                            locations: locationGroup.locations.map((v) => ({ id: v['locationID'], name: v['name'] }))
                        });
                    });
                } else {
                    this.locationGroupsUnavailable = true;
                }
            }
        );
        this.subscriptions.push(locationGroupSubscription);
    }

    public getLocations(keepSelectedLocs: boolean = false) {
        this.filteredLocations = [];
        this.locations = [];

        if (this.selectedCustomerId === 0) {
            return;
        }

        const args: LocationArgs = {
            customerId: this.selectedCustomerId,
            IncludeInactiveLocations: this.shouldIncludeInactiveLocations,
        }

        this.usersService.getLocationsList(args).subscribe((locations: Locations[]) => {
            const filteredLocations = locations?.filter(v => v.series === MonitorSeriesNames.triton || v.series === MonitorSeriesNames.echo);

            if (filteredLocations && filteredLocations.length) {
                this.locationsUnavailable = false;
                this.locations = filteredLocations.map(v => ({ id: v.locationId, name: v.locationName, isChecked: true }));
                this.minimumLocationError = false;
                this.filterLocations(this.locationSearch.value, keepSelectedLocs);
                this.selectAllLocations = true;
                this.onLocationChecked();
            }
            else {
                this.locationsUnavailable = true;
            }
        });
    }

    private filterLocations(searchValue: string, keepSelectedLocs = false) {
        this.filteredLocations = this.locations.filter(v => v.name.toLowerCase().includes(searchValue.toLowerCase()));

        if (keepSelectedLocs && this.selectedLocationIds.length) {
            this.filteredLocations = this.filteredLocations.map(v => this.selectedLocationIds.includes(v.id) ? ({ ...v, isChecked: true }) : v);
        }
    }

}
