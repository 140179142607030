import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Inject,
    ViewChild,
    ChangeDetectorRef,
    AfterViewInit,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort, MatSortable } from '@angular/material/sort';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataEditPreviewParams } from 'app/shared/models/data-edit';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { CUSTOM_NOTIFICATION_TIMEOUT } from '../report/site-commentary-report/site-commentary.model';
import { UsersService } from '../admin/users.service';
import { DataEditService } from 'app/shared/services/data-edit.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { SILT_ENTITY, UnitOfMeasureType } from 'app/shared/constant';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';

const SILT_EDITOR_ID = 1707;

@Component({
    selector: 'app-silt-editor',
    templateUrl: './silt-editor.component.html',
    styleUrls: ['./silt-editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiltEditorComponent implements OnInit, AfterViewInit {
    @ViewChild('table') private table;
    @ViewChild('selectedDateInput') private dateInput;
    @ViewChild(MatSort) public tableSort: MatSort;
    public data;
    public isLoading = true;
    public addMore: boolean;
    public isCustomDateTouched: boolean;
    public selectedDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
    public form: FormGroup;
    private editDic = {};
    public siltEditorDescriptionColumns = ['timestamp', 'editedValue', 'user', 'editDate'];
    public siltEditorDataSource: any;
    public updateSiltPoints = [];
    public customerDateFormat: string;
    public datePickerType = 'datetime';
    private originalSource$: any[];
    public enableSave: boolean;
    public unit: string;
    public userHasPermissionOnEdit = false;
    public isMetric: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        public dialogRef: MatDialogRef<SiltEditorComponent>,
        public fb: FormBuilder,
        private dataEditingService: DataEditService,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private dateutilService: DateutilService,
        private snackBarService: SnackBarNotificationService,
        private customerService: CustomerService,
        private userService: UsersService,
    ) {
        this.data = data;
        this.isMetric = this.customerService.customer && (Number(this.customerService.customer.unitsType) === UnitOfMeasureType.METRIC);
        this.form = this.fb.group({
            timeStamp: [{ value: this.selectedDate, disabled: false }],
            reading: [{ value: '', disabled: false }, [Validators.pattern(
                this.isMetric
                    ? REGEX_CONFIG.installationMetricValuePattern
                    : REGEX_CONFIG.installationValuePattern,
            ), Validators.min(0)]],
        });
    }

    public ngOnInit() {
        this.checkUserPermission();
        this.siltEditorDataSource = new MatTableDataSource([]);
        this.customerService.getCustomerUnits(this.data.cid).subscribe((units) => {
            this.unit = units.depthUnit;
        });
        this.dateutilService.dateFormat.subscribe((newDateFormat) => {
            // Getting customer dateformat from dateUtil Service
            this.customerDateFormat =
                this.datePickerType === 'datetime'
                    ? `${String(this.dateutilService.getFormat())} ${this.dateutilService.getTimeFormat()}`
                    : this.dateutilService.getFormat();
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
        this.dataEditingService.getAllEntityData(this.data.cid, this.data.lid, SILT_EDITOR_ID).subscribe((res: any) => {
            if (res && ((res.data && res.data.length > 0) || (res.editedData && res.editedData.length > 0))) {
                const source = [];
                const map = new Map();
                res.data.forEach((item) => map.set(item.dateTime, item));
                res.editedData.forEach((item) => map.set(item.dateTime, { ...map.get(item.dateTime), ...item }));
                const mergedArr = Array.from(map.values());
                mergedArr.forEach((d, i) => {
                    if (!d['ignore']) {
                        const obj = {};
                        obj['timestamp'] = d.dateTime;
                        obj['user'] = d.user ? d.user : ' - ';
                        obj['editedValue'] = d.reading;
                        if (d.editDate) {
                            obj['editDate'] = this.dateutilService.getLocalDateFromUTCDate(new Date(d.editDate));
                        }
                        source.push(obj);
                    }
                });
                this.originalSource$ = source.map((x) => Object.assign({}, JSON.parse(JSON.stringify(x))));
                this.siltEditorDataSource.data = source.sort(
                    (a, b) => (new Date(b.timestamp) as any) - (new Date(a.timestamp) as any),
                );
                if (this.tableSort) {
                    this.tableSort.sort(<MatSortable>{
                        id: 'timestamp',
                        start: 'asc',
                    });
                }
                this.siltEditorDataSource.sort = this.tableSort;
            } else {
                this.originalSource$ = [];
            }
            this.isLoading = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
    }

    private checkUserPermission() {
        this.userHasPermissionOnEdit = this.userService.isBasicDataEditingAllowed.getValue();

        if (this.userHasPermissionOnEdit) {
            this.siltEditorDescriptionColumns.push('action');
        }
    }

    public ngAfterViewInit() {
        if (this.tableSort) {
            this.tableSort.sort(<MatSortable>{
                id: 'timestamp',
                start: 'asc',
            });
            this.siltEditorDataSource.sort = this.tableSort;
        }
    }

    public checkDateChange(event) {
        if (!event.value) {
            this.uiUtilsService.safeChangeDetection(this.cdr);
            return;
        }
        this.isCustomDateTouched = true;
        this.selectedDate = event.value;
        this.form.controls['timeStamp'].setValue(event.value);
    }

    public addRow() {
        this.form.controls['timeStamp'].enable();

        if (this.editDic[this.form.controls['timeStamp'].value] !== undefined) {
            this.siltEditorDataSource.data[this.editDic[this.form.controls['timeStamp'].value]].editedValue =
                this.form.controls['reading'].value;
        } else {
            let timestampInput;
            try {
                let dateValue = this.dateInput.nativeElement.value;
                if (this.customerDateFormat === 'dd/MM/yyyy HH:mm:ss') {
                    const day = this.dateInput.nativeElement.value.substr(0, 3);
                    const month = this.dateInput.nativeElement.value.substr(3, 3);
                    dateValue = month + day + this.dateInput.nativeElement.value.substr(6);
                }
                timestampInput = new Date(dateValue).toISOString();
            } catch (error) {
                timestampInput = this.form.controls['timeStamp'].value.toISOString();
            }
            if (!this.siltEditorDataSource) {
                this.siltEditorDataSource = new MatTableDataSource([
                    {
                        timestamp: timestampInput,
                        editedValue: this.form.controls['reading'].value,
                        user: this.data.user,
                        editDate: new Date().toISOString(),
                    },
                ]);
            } else {
                this.siltEditorDataSource.data.push({
                    timestamp: timestampInput,
                    editedValue: this.form.controls['reading'].value,
                    user: this.data.user,
                    editDate: new Date().toISOString(),
                });
            }
        }
        this.siltEditorDataSource.data.sort((a, b) => (new Date(b.timestamp) as any) - (new Date(a.timestamp) as any));
        if (this.table.dataSource) {
            this.table.renderRows();
        }
        this.enableSave = true;
        this.form.reset();
        this.addMore = !this.addMore;
    }

    public saveSiltValues() {
        this.isLoading = true;

        this.originalSource$.forEach((x) => {
            if (x.ignore) {
                this.updateSiltPoints.push({
                    timeStamp: x.timestamp,
                    reading: x.editedValue,
                    eid: SILT_EDITOR_ID,
                    reason: 'silt editor',
                    ignore: true,
                });
            }
        });

        this.siltEditorDataSource.data.forEach((x) => {
            const original = this.originalSource$.find((j) => j.timestamp === x.timestamp);
            if (!original || original.editedValue !== x.editedValue) {
                const edit = original ? original.editedValue !== x.editedValue : false;
                const date = new Date(x.timestamp);
                this.updateSiltPoints.push({
                    timeStamp: edit
                        ? x.timestamp
                        : new Date(
                              Date.UTC(
                                  date.getFullYear(),
                                  date.getMonth(),
                                  date.getDate(),
                                  date.getHours(),
                                  date.getMinutes(),
                                  0,
                              ),
                          ),
                    reading: x.editedValue,
                    eid: SILT_EDITOR_ID,
                    reason: 'silt editor',
                    ignore: false,
                });
            }
        });

        const params: DataEditPreviewParams = {
            start: this.data.startDate,
            end: this.data.endDate,
            dataEditType: 3,
            depthOnY: true,
            bestFit: null,
            temporaryFlags: null,
            snapPoints: null,
            updatePoints: this.updateSiltPoints,
            blockEntity: null,
            // properties for the min max avg table info that needs to refresh with the data edit preview call
            summaryData: true,
            summaryEntityIds: this.dataEditingService.newHydroData$.value.map(x => x.entityId).filter(e => e !== SILT_ENTITY),
        };

        const preview = this.dataEditingService.dataEditPreview(this.data.cid, this.data.lid, params).subscribe(
            (res) => {
                const submit = this.dataEditingService
                    .dataEditSubmit(this.data.cid, this.data.lid, 'silt editor')
                    .subscribe(
                        (resSubmit) => {
                            this.isLoading = false;
                            const lastValue = this.siltEditorDataSource.data.sort(
                                (a, b) => (new Date(a.timestamp) as any) - (new Date(b.timestamp) as any),
                            )[this.siltEditorDataSource.data.length - 1];
                            const update = {
                                updated: true,
                            };
                            if (lastValue) {
                                update['lastValue'] = lastValue;
                            } else {
                                update['lastValue'] = { editedValue: '' };
                            }
                            this.cancelClose(update);
                        },
                        (error) => {
                            this.isLoading = false;
                            this.snackBarService.raiseNotification('Silt Submit Failed', error, {
                                panelClass: 'custom-error-snack-bar',
                            }, false);
                            submit.unsubscribe();
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                        },
                    );
            },
            (error) => {
                this.isLoading = false;
                this.snackBarService.raiseNotification('Silt preview Failed', error, {
                    panelClass: 'custom-error-snack-bar',
                }, false);
                preview.unsubscribe();
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
    }

    public addMoreOpen() {
        this.form.controls['timeStamp'].enable();
        this.form.controls['timeStamp'].setValue(this.selectedDate);
        this.addMore = !this.addMore;
        setTimeout(() => {
            this.dateInput.nativeElement.disabled = false;
        }, 200);
    }

    public cancelClose(update) {
        this.dialogRef.close(update);
    }

    public editItem(el, i) {
        this.addMore = true;
        this.form.controls['timeStamp'].setValue(el.timestamp);
        this.form.controls['timeStamp'].disable();
        this.form.controls['reading'].setValue(el.editedValue);
        this.editDic[el.timestamp] = i;
        setTimeout(() => {
            this.dateInput.nativeElement.disabled = true;
        }, 200);
    }

    public deleteItem(el, i) {
        const original = this.originalSource$.find((x) => x.timestamp === el.timestamp);
        if (original) {
            this.enableSave = true;
            original.ignore = true;
        }
        this.siltEditorDataSource.data.splice(i, 1);
        this.table.renderRows();
        this.dataEditingService.siltLevelEdited.next(null);
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public exportSilt() {
        const csvData = new Array<Object>();
        const csvHeaders = ['Timestamp', 'Value', 'Edited By', 'Edited On'];

        if (this.siltEditorDataSource && this.siltEditorDataSource.data && this.siltEditorDataSource.data.length > 0) {
            this.siltEditorDataSource.data.forEach((arrayItem, arrayItemIndex) => {
                const newItem = new Array<string>();

                newItem.push(arrayItem.timestamp);
                newItem.push(arrayItem.editedValue);
                newItem.push(arrayItem.user);
                newItem.push(arrayItem.editDate);

                csvData.push(newItem);
            });

            const options = {
                showLabels: true,
                headers: csvHeaders,
                title: `Silt Editor Data Points`,
                showTitle: true,
            };

            const result = new AngularCsv(csvData, 'DATA EDITING AUDIT REPORT', options);
        }
    }
}
