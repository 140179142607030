import { Component, ChangeDetectionStrategy, ViewEncapsulation, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UsersService } from 'app/pages/admin/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
    LocationAbstarct,
    LocationGroupSelectable,
    FlowMonitorExport,
    DTR_NONE_AVERAGING,
    DTR_SAME_AVERAGING_AS_HYDROGRAPHS,
    FLOW_MONITORING_EXPORT_SECTIONS,
    DATA_AVERAGING_COLLECTION,
    GROUPING_SECTIONS,
    FlowMonitoringSelectable,
    REPORT_TEMPLATE_COLLECTION,
    EXPORT_TYPE_COLLECTION,
    REPORT_TYPE,
    CUSTOM_NOTIFICATION_TIMEOUT,
    FMR_CREATE_TIMEOUT,
    FlowMonitorExportSectionTypes,
} from './site-commentary.model';
import {
    DFINAL_ENTITY,
    V_FINAL_ENTITY,
    Q_FINAL_ENTITY,
    NOTIFICATION_TIMEOUT,
    R_FINAL_ENTITY,
    RAIN_ENTITY,
    RAIN_ENTITY_FULL,
} from 'app/shared/constant';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { EntityService } from 'app/shared/services/entity.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { AuthService } from 'app/shared/services/auth.service';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { ERROR_NAME_ALREADY_EXIST, FlowMonitoringService } from 'app/shared/services/flow-monitoring.service';
import { SelectableGroup } from 'app/shared/models/selectable';
import { EntityResponseItem, QUICK_DATE_RANGES } from 'app/shared/models/view-data';
import { AppQueryParams, customerQueryParam } from 'app/shared/models/customer';
import { LocationArgs, Locations, LocationStatus } from 'app/shared/models/locations';
import { LocationGroup } from 'app/shared/models/location-group';
import { IComponentDialog } from 'app/shared/models/comopnent-cofirmation';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { FmrSaveConfigurationDialogComponent, FmrSaveConfigurationDialogComponentButtons, FmrSaveConfigurationDialogComponentResult } from './fmr-save-configuration-dialog/fmr-save-configuration-dialog.component';
import { FmrOpenConfigurationDialogComponent, FmrOpenConfigurationDialogComponentResult } from './fmr-open-configuration-dialog/fmr-open-configuration-dialog.component';
import { FlowMonitoringConfigRequest, FlowMonitoringConfigResponse } from 'app/shared/models/flow-monitoring-config';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { DateService } from 'app/shared/services/date.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
@Component({
    selector: 'ads-site-commentary-report',
    templateUrl: './site-commentary-report.component.html',
    styleUrls: ['./site-commentary-report.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class SiteCommentaryReportComponent implements OnInit, OnDestroy {
    public isLoading = true;
    public customerDateFormat: string;
    public userEmail: string;
    public startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, 0, 0, 0);
    public endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59);
    public maxDate = new Date();
    public startDateData = new FormControl(
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, 0, 0, 0),
    );
    public endDateData = new FormControl(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59));

    public displayStartDateErrMsg: boolean;
    public displayEndDateErrMsg: boolean;
    public invalidDateRange: boolean;
    public invalidStartDate: boolean;
    public invalidEndDate: boolean;
    public reportTemplates: Array<FlowMonitoringSelectable> = REPORT_TEMPLATE_COLLECTION;
    public graphDataAveraging: Array<FlowMonitoringSelectable> = DATA_AVERAGING_COLLECTION.filter(v => v.id !== 0); // #33596 do not include None averaging
    public dataAveragingSelections: Array<FlowMonitoringSelectable> = DATA_AVERAGING_COLLECTION;
    public groupedBySelections: Array<FlowMonitoringSelectable> = GROUPING_SECTIONS;

    public entities: Array<SelectableGroup> = [];
    public selectedEntities: Array<SelectableGroup> = [];
    public entitiesCloneList: Array<SelectableGroup> = [];
    public defaultEntitesList: Array<number> = [DFINAL_ENTITY, V_FINAL_ENTITY, RAIN_ENTITY, Q_FINAL_ENTITY];
    public selectedEntitiesIds: Array<number> = this.defaultEntitesList;

    public sections: Array<SelectableGroup> = FLOW_MONITORING_EXPORT_SECTIONS;
    public selectedSections: Array<SelectableGroup> = FLOW_MONITORING_EXPORT_SECTIONS;
    public locationType: string;
    public locationTypeCollection: Array<FlowMonitoringSelectable> = EXPORT_TYPE_COLLECTION;
    public customerId: number;
    public locations: Array<LocationAbstarct> = [];
    public initialLocationsList: Array<LocationAbstarct> = [];
    public dismissText: string;
    public locationGroupList: Array<LocationGroupSelectable> = [];
    public flowMonitoringExport = new FlowMonitorExport();
    public selectAllLocations = false;
    public minimumLocationError: boolean;
    public locationSearch = new FormControl();
    public searchModeEnabled: boolean;
    public previousSearchLocs: number[];
    public isLocationGroupInvalid: boolean;
    public invalidEntities: boolean;
    public invalidSections: boolean;
    public invalidDateSelected: boolean;
    public locationGroupsUnavailable: boolean;
    public locationsUnavailable: boolean;
    public noLocationsErrorMessage: string;
    public noLocationGroupsErrorMessage: string;
    public noEntitiesErrorMessage: string;
    private subscription = new Array<Subscription>();
    public flowMonitoringExportSuccessMessage: string;
    public flowMonitoringExportFailureMessage: string;
    public flowMonitoringExportNoDataAvailableMessage: string;
    public flowMonitoringExportTitle: string;

    public QUICK_DATE_RANGES = QUICK_DATE_RANGES;
    public showScattergraphFilters = true;
    public ScattergraphIsoQ = true;
    public ScattergraphFroude = false;
    public ScattergraphPipeOverlay = true;
    public shouldIncludeInactiveLocations: boolean;

    public recentConfigs: FlowMonitoringConfigResponse[];
    public conf: FlowMonitoringConfigRequest;

    preSelectedLocationGroup: LocationGroupSelectable;

    constructor(
        private dateutilService: DateutilService,
        private entityService: EntityService,
        private flowMonitoringService: FlowMonitoringService,
        private usersService: UsersService,
        private activatedRoute: ActivatedRoute,
        private snackBarService: SnackBarNotificationService,
        private locationGroupService: LocationGroupService,
        private translate: TranslateService,
        private router: Router,
        private dialog: MatDialog,
        private authService: AuthService,
        private uiUtilsService: UiUtilsService,
        private cdr: ChangeDetectorRef,
        private dateService: DateService
    ) {
        this.locationSearch.valueChanges
            .pipe(
                debounceTime(400),
                distinctUntilChanged(),
                switchMap((locationSearch) => this.filterLocations(locationSearch)),
            )
            .subscribe((res) => {});
    }

    public ngOnInit() {
        this.usersService.isAdvancedReportsAllowed.subscribe((res) => {
            const isAdvancedReportsAllowed = res.value ? res.value : false;
            if(!isAdvancedReportsAllowed) {
                this.router.navigate(['/'], {
                    queryParams: {
                        c: this.customerId,
                    },
                    relativeTo: this.activatedRoute,
                });
            }
        })

        this.activatedRoute.queryParamMap.subscribe((params) => {
            const currentCustomerId = Number(params.get(customerQueryParam));
            if (currentCustomerId && this.customerId && currentCustomerId !== this.customerId) {
                this.customerId = currentCustomerId;

                this.resetFlowMonitoringPage();
            }
            this.customerId = currentCustomerId;
        });
        const dateFormatSubscription = this.dateutilService.dateFormat.subscribe((newDateFormat) => {
            // Getting customer dateformat from dateUtil Service
            this.customerDateFormat = this.dateutilService.getFormat() + ' ' + this.dateutilService.getTimeFormat();
            this.isLoading = false;
        });
        this.subscription.push(dateFormatSubscription);
        this.userEmail = this.authService.user.email;

        this.applyTranslations();

        this.loadRecentConfigurations();
    }

    private loadRecentConfigurations() {
        this.subscription.push(
            this.flowMonitoringService.configurationsRecentGet(this.customerId).subscribe((confs) => {
                this.recentConfigs = confs;
                this.getLocations();
                this.getLocationGroups();
                this.initFlowMonitoringPage();
            })
        );
    }

    public ngOnDestroy() {
        this.subscription.forEach((subscription) => subscription.unsubscribe());
    }

    public selectedStartDate(date?: Date) {
        if (date) {
            this.startDate = date;
            this.flowMonitoringExport.startTime = new Date(
                this.startDate.getTime() - this.startDate.getTimezoneOffset() * 60000,
            ).toISOString();
        }
    }

    public selectedEndDate(date?: Date) {
        if (date) {
            this.endDate = date;
            this.flowMonitoringExport.endTime = new Date(
                this.endDate.getTime() - this.endDate.getTimezoneOffset() * 60000,
            ).toISOString();
        }
    }

    /**Method to retrieve entities from API for selected locations
     * Accepts @param selectedLocations array
     */
    public getSelectedEntities(selectedLocations: Array<LocationAbstarct>) {
        this.isLoading = true;
        this.selectedEntities = [];
        const entitiesSubscription = this.entityService
            .getEntitiesByLocation(
                this.customerId,
                selectedLocations.map(({ id }) => id),
            )
            .subscribe(
                (entities: Array<EntityResponseItem>) => {
                    if (entities && entities.length) {
                        const filteredEntities = new Array<SelectableGroup>();
                        entities.forEach((entity: EntityResponseItem) => {
                            const isChecked = new Set(this.selectedEntitiesIds).has(entity.entityId);

                            filteredEntities.push({
                                name: entity.entityName,
                                id: entity.entityId,
                                groupName: entity.displayGroupName,
                                groupId: entity.displayGroup,
                                isChecked: isChecked,
                            });
                        });
                        this.entities = this.entitiesCloneList = filteredEntities;
                        this.selectedEntities = this.entities.filter((item) => item.isChecked);
                        this.isLoading = false;
                    }

                    // #26496 add RAIN entity for USGS
                    const isRain = this.entities.some((e) => e.id === RAIN_ENTITY);
                    if(!isRain) {
                        for(const loc of selectedLocations) {
                            if(loc.series && loc.series.toLowerCase() === 'usgs') {
                                const isChecked = new Set(this.selectedEntitiesIds).has(RAIN_ENTITY_FULL.entityId);

                                this.entities.push({
                                    name: RAIN_ENTITY_FULL.entityName,
                                    id: RAIN_ENTITY_FULL.entityId,
                                    groupName: RAIN_ENTITY_FULL.displayGroupName,
                                    groupId: RAIN_ENTITY_FULL.displayGroup,
                                    isChecked: isChecked
                                })
                                break;
                            }
                        }
                        selectedLocations.forEach((loc) => {
                        });
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.snackBarService.raiseNotification(this.noEntitiesErrorMessage, this.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    },false);
                },
            );
        this.subscription.push(entitiesSubscription);
    }

    /**Method invoked on selecting/deselecting entities from multi select dropdown.
     * accepts @param entities list selected
     */
    public handleSelectedEntities(entities: Array<SelectableGroup>) {
        this.invalidEntities = !(entities && entities.length);
        this.selectedEntities = [];
        this.selectedEntities = entities.filter((entity: SelectableGroup) => entity.isChecked);
        this.selectedEntitiesIds = this.selectedEntities.map((x) => x.id);
        this.flowMonitoringExport.entityIds = this.selectedEntities.map((entity) => entity.id);
    }

    /**Method invoked on selecting/deselecting sections from export checkboxes.
     * accepts @param event Checkbox change event
     */
    public handleSelectedSections() {
        this.selectedSections = this.sections.filter(v => v.isChecked);
        this.invalidSections = !(this.selectedSections && this.selectedSections.length);

        this.flowMonitoringExport.sections = this.selectedSections.map((section) => section.id);
        this.showScattergraphFilters = this.selectedSections.some((v) => v.id === 3);
    }

    public checkSelectedSection(sectionId: number) {
        return !(this.sections.find(v => v.id === sectionId).isChecked);
    }

    public handleGraphAveragingChange(event: MatSelectChange) {
        this.flowMonitoringExport.SummarizeIntervalHydrograph = event.value;
        this.flowMonitoringExport.SummarizeIntervalScattergraph = event.value;
    }

    private updateFlowMonitoringObject() {
        if (this.showScattergraphFilters) {
            this.flowMonitoringExport.ScattergraphFroude = this.ScattergraphFroude;
            this.flowMonitoringExport.ScattergraphIsoQ = this.ScattergraphIsoQ;
            this.flowMonitoringExport.ScattergraphPipeOverlay = this.ScattergraphPipeOverlay;
        }
        this.flowMonitoringExport.cid = this.customerId;
        this.flowMonitoringExport.includeUptime = true;
        this.flowMonitoringExport.entityIds = this.selectedEntities.map((entity) => entity.id);
        this.flowMonitoringExport.sections = this.selectedSections.map((section) => section.id);

        // If daily tab report same averaging is selected,
        // fmr export needs to know that section is is still considered DTR none averaging on the backend,
        // we just have to set the flag 'useSummarizeIntervalForDailyTabular'
        if (this.flowMonitoringExport.sections.some(sec => sec === 5)) {
            this.flowMonitoringExport.sections.push(4);
        }
        this.flowMonitoringExport.useSummarizeIntervalForDailyTable = this.selectedSections
            .some((sec: SelectableGroup) => sec.id == DTR_SAME_AVERAGING_AS_HYDROGRAPHS);
            
        this.flowMonitoringExport.startTime = new Date(
            this.startDate.getTime() - this.startDate.getTimezoneOffset() * 60000,
        ).toISOString();
        this.flowMonitoringExport.endTime = new Date(
            this.endDate.getTime() - this.endDate.getTimezoneOffset() * 60000,
        ).toISOString();
    }

    // Method to export flow monitoring selections
    public exportFlowMonitoringReport() {
        this.updateFlowMonitoringObject();

        const flowMonitoringExportSubscription = this.flowMonitoringService
            .exportFlowMonitoringReport(this.flowMonitoringExport)
            .subscribe(
                (exportResult) => {
                    this.isLoading = false;
                    if (!exportResult) {
                        this.dialog.open(ConfirmationDialogComponent, {
                            disableClose: true,
                            data: <IComponentDialog>{
                                title: this.flowMonitoringExportTitle,
                                message: this.flowMonitoringExportNoDataAvailableMessage,
                                cancelText: '',
                                okText: 'OK',
                                hidebackContent: false,
                            },
                        });
                    }
                    flowMonitoringExportSubscription.unsubscribe();
                },
                (error) => {
                    this.isLoading = false;
                    this.snackBarService.raiseNotification(this.flowMonitoringExportFailureMessage, this.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    }, false);
                    flowMonitoringExportSubscription.unsubscribe();
                },
            );
        // naviagate to Home page after export request initiated
        this.router
            .navigate(['/dashboard'], {
                queryParams: <AppQueryParams>{
                    c: this.customerId,
                },
            })
            .then(() => {
                // showing notification messaage on sucessful navigation
                this.snackBarService.raiseNotification(this.flowMonitoringExportSuccessMessage, this.dismissText, {
                    duration: FMR_CREATE_TIMEOUT,
                });
            });
    }

    public getLocations() {

        this.loadLocationsSubscription().subscribe(()=>{

            let locations = this.locations.filter((location) => location.isChecked);
            this.minimumLocationError = false;
            this.flowMonitoringExport.lids = locations.map((location) => location.id);
            if(locations.length > 0)
            {
               this.getSelectedEntities(locations);
            }

        });
    }

    // method to fetch all locations from API for current customer
    private loadLocationsSubscription() {
        this.isLoading = true;
        const locationArgs = <LocationArgs>{
            customerId: this.customerId,
            IncludeInactiveLocations: this.shouldIncludeInactiveLocations,
        };
        return this.usersService.getLocationsList(locationArgs).pipe(
            tap(
                (locationList: Locations[]) => {
                if (locationList && locationList.length) {
                    locationList = locationList.filter(x => x.viewable);
                    locationList = locationList.sort((a, b) =>
                        a.locationName && b.locationName
                            ? a.locationName.localeCompare(b.locationName)
                            : a.locationName
                            ? 1
                            : -1,
                    );

                    this.locationsUnavailable = false;

                    const locationsNew = [];
                    locationList.forEach((location: Locations) => {
                        locationsNew.push({
                            id: location.locationId,
                            locationName: location.locationName,
                            isChecked: this.locations.find(x => x.id === location.locationId && x.isChecked) ? true : false,
                            customerID: location.customerID,
                            series: location.series,
                            isActiveLocation:
                                location.status === LocationStatus.Active ||
                                location.status === LocationStatus.Maintenance,
                        });
                    });

                    this.locations = locationsNew;
                    this.initialLocationsList = this.locations;


                } else {
                    this.locationsUnavailable = true;
                }
                this.locationType = REPORT_TYPE.LOCATION; // setting location as default on page load
                this.handleLocationTypeChange(REPORT_TYPE.LOCATION);

                this.isLoading = false;
            },
            (error) => {
                this.isLoading = false;
                this.snackBarService.raiseNotification(this.noLocationsErrorMessage, this.dismissText, {
                    panelClass: 'custom-error-snack-bar',
                }, false);
                this.locationsUnavailable = true;
                this.locationType = REPORT_TYPE.LOCATION; // setting location as default on page load
                this.handleLocationTypeChange(REPORT_TYPE.LOCATION);
            }
        ));
    }

    // method to fetch all location groups from API for current customer
    public getLocationGroups() {
        this.isLoading = true;
        this.locationGroupList = [];
        const locationGroupSubscription = this.locationGroupService.getLocationGroups(this.customerId).subscribe(
            (locationGroupList) => {
                if (locationGroupList && locationGroupList.locationGroups && locationGroupList.locationGroups.length) {
                    this.isLoading = false;
                    this.locationGroupsUnavailable = false;
                    
                    const formattedLocationGroups: LocationGroupSelectable[] = []; 
                    locationGroupList.locationGroups.forEach((locationGroup: LocationGroup) => {
                        formattedLocationGroups.push({
                            id: locationGroup.locationGroupID,
                            name: locationGroup.name,
                            isChecked: false,
                        });
                    });

                    this.locationGroupList = formattedLocationGroups;
                } else {
                    this.locationGroupsUnavailable = true;
                    this.isLoading = false;
                }
            },
            (error) => {
                this.isLoading = false;
                this.snackBarService.raiseNotification(this.noLocationGroupsErrorMessage, this.dismissText, {
                    panelClass: 'custom-error-snack-bar',
                }, false);
            },
        );
        this.subscription.push(locationGroupSubscription);
    }

    /**Method invoked on selecting locationgroup */
    public handleSelectedLocationsGroup(selectedLocationGroup) {
        if (!selectedLocationGroup || !selectedLocationGroup.length || !selectedLocationGroup[0]) {
            this.resetEntitiesList();
            return;
        }
        this.isLocationGroupInvalid = false;
        if (this.flowMonitoringExport && this.flowMonitoringExport.locationGroupId !== selectedLocationGroup[0].id) {
            this.getSelectedEntities(selectedLocationGroup);
            this.flowMonitoringExport.locationGroupId = selectedLocationGroup[0].id;
        } else if (
            this.flowMonitoringExport &&
            this.flowMonitoringExport.locationGroupId === selectedLocationGroup[0].id
        ) {
            this.entities = this.entitiesCloneList;
            this.selectedEntities = this.entities.filter((entity) => entity.isChecked);
        }
    }

    /**Method invoked on choosing location type (location/locationgroup) to be exported
     * Accepts @param locationTypeSelected used to switch on to particular type
     */
    public handleLocationTypeChange(locationTypeSelected: string) {
        this.isLoading = true;
        if (locationTypeSelected === REPORT_TYPE.LOCATION) {
            this.resetEntitiesList();
            this.flowMonitoringExport.locationGroupId = 0;
            this.isLocationGroupInvalid = false;
        } else if (locationTypeSelected === REPORT_TYPE.LOCATIONGROUP) {
            // #25271 reset group autocomplete, remove last choice - so just touch this so autocomplete will refresh
            this.locationGroupList = this.locationGroupList.filter(x => true);

            this.toggleSelectAllLocations(false);
            this.flowMonitoringExport.lids = [];
            this.minimumLocationError = false;
        }
        this.invalidEntities = false;
        this.isLoading = false;
    }

    // Method to reset page to defaults
    public resetFlowMonitoringPage() {
        this.flowMonitoringExport = new FlowMonitorExport();
        this.conf = null;
        this.loadRecentConfigurations();
        this.getLocations();
        this.getLocationGroups();
        this.initFlowMonitoringPage();
    }

    // Method to intialize flow monitor export and other page defaults
    public initFlowMonitoringPage() {
        this.locationType = undefined;
        this.flowMonitoringExport.sections = [];
        this.sections
            .filter(section => section.id !== DTR_SAME_AVERAGING_AS_HYDROGRAPHS)
            .forEach((section) => (section.isChecked = true));
        this.resetEntitiesList();
        this.flowMonitoringExport.groupBy = 0;

        if(this.authService.user.isIdex) {
            this.flowMonitoringExport.reportTemplate = 1;
        } else {
            this.reportTemplates = this.reportTemplates.filter((x) => x.id === 0);
            this.flowMonitoringExport.reportTemplate = 0;
        }
    }

    // Method invoked on selection/unselection of location(s) from the list of locations
    public onLocationChange() {
        let locations = this.locations.filter((location) => location.isChecked);
        if (
            this.searchModeEnabled &&
            this.initialLocationsList.some((location) => location.isChecked) &&
            locations.length
        ) {
            const retainedLocations = this.initialLocationsList.filter(
                (location) => location.isChecked && !locations.includes(location),
            );
            locations = [...locations, ...retainedLocations];
        }
        if (!(locations && locations.length)) {
            this.minimumLocationError = true;
            this.resetEntitiesList();
            return;
        }
        this.minimumLocationError = false;
        this.flowMonitoringExport.lids = locations.map((location) => location.id);
        this.getSelectedEntities(locations);
    }

    // Method to handle selecting/unselecting all locations at once
    public toggleSelectAllLocations(isChecked) {
        this.locations.forEach((location) => (location.isChecked = isChecked));
        this.selectAllLocations = isChecked;
        if (this.selectAllLocations) {
            this.handleSelectAllValidation();
        } else if (!this.selectAllLocations && this.initialLocationsList.some((location) => location.isChecked)) {
            const retainSelectedLocations = this.initialLocationsList.filter((location) => location.isChecked);
            this.getSelectedEntities(retainSelectedLocations);
            this.minimumLocationError = false;
            this.flowMonitoringExport.lids = retainSelectedLocations.map((selectedLocation) => selectedLocation.id);
        } else {
            this.resetEntitiesList();
            this.minimumLocationError = true;
        }
    }

    // Method to validate select all scenarios to obtain final selected list of locations to be exported
    public handleSelectAllValidation() {
        let selectedLocations = [];
        if (this.searchModeEnabled && this.flowMonitoringExport.lids.length) {
            selectedLocations = [
                ...this.locations,
                ...this.initialLocationsList.filter((location) => this.flowMonitoringExport.lids.includes(location.id)),
            ];
            this.flowMonitoringExport.lids = [...this.flowMonitoringExport.lids, ...this.locations.map((x) => x.id)];
        } else {
            this.flowMonitoringExport.lids = this.locations.map((x) => x.id);
        }
        this.getSelectedEntities(selectedLocations.length ? selectedLocations : this.locations);
        this.minimumLocationError = false;
    }

    // Method to reset entities list and selected entities list to defaults
    public resetEntitiesList() {
        this.entities = [];
        this.selectedEntities = [];
        this.invalidEntities = false;
    }

    // Method used for search/filtering of locations available
    public filterLocations(searchString: string) {
        this.searchModeEnabled = true;
        this.locations = this.initialLocationsList.filter((x) =>
            x.locationName.toLowerCase().includes(searchString.toLowerCase()),
        );

        // checks all customer selected or not
        if (searchString.trim() === '') {
            this.searchModeEnabled = false;
        }
        if (this.locations.filter((x) => x.isChecked).length === this.locations.length && this.locations.length !== 0) {
            this.selectAllLocations = true;
        } else {
            this.selectAllLocations = false;
        }
        return this.locations;
    }

    /**Method invoked on manual input of locationgroup name
     * Accepts @param locationGroupInput entered
     */
    public handleLocationGroupInput(locationGroupInput: string) {
        if (!locationGroupInput && !this.flowMonitoringExport.locationGroupId) {
            return;
        } else if (!locationGroupInput && this.flowMonitoringExport.locationGroupId) {
            this.isLocationGroupInvalid = true;
            this.resetEntitiesList();
            return;
        }
        this.flowMonitoringExport.locationGroupId
            ? (this.isLocationGroupInvalid = !this.locationGroupList.some(
                  (locationGroup) => locationGroup.name === locationGroupInput,
              ))
            : (this.isLocationGroupInvalid = true);
        if (this.isLocationGroupInvalid) {
            this.resetEntitiesList();
        }
    }

    // Method to reset all date time picker errors
    public resetDateErrors() {
        this.invalidEndDate = false;
        this.invalidStartDate = false;
        this.displayStartDateErrMsg = false;
        this.displayEndDateErrMsg = false;
        this.invalidDateRange = false;
    }

    // Method to retrieve static keys
    public applyTranslations() {
        const translateKeys: Array<string> = [
            'FLOW_MONITORING.NO_LOCATIONS_NOTIFICATION_MESSAGE',
            'FLOW_MONITORING.NO_LOCATION_GROUPS_NOTIFICATION_MESSAGE',
            'FLOW_MONITORING.NO_ENTITIES_NOTIFICATION_MESSAGE',
            'COMMON.DISMISS_TEXT',
            'FLOW_MONITORING.EXPORT_REQUEST_SUCCESS_NOTIFICATION_MESSAGE',
            'FLOW_MONITORING.EXPORT_REQUEST_FAILURE_NOTIFICATION_MESSAAGE',
            'FLOW_MONITORING.EXPORT_NO_DATA_AVAILABLE_TOAST_MESSAGE',
            'FLOW_MONITORING.TITLE',
            'COMMON.NAME_ALREADY_EXISTS',
            'COMMON.ERROR_ON_SAVE'
        ];

        this.translate.get(translateKeys).subscribe((translateValues) => {
            if (!translateValues) {
                return;
            }
            this.noLocationsErrorMessage = translateValues['FLOW_MONITORING.NO_LOCATIONS_NOTIFICATION_MESSAGE'];
            this.noLocationGroupsErrorMessage =
                translateValues['FLOW_MONITORING.NO_LOCATION_GROUPS_NOTIFICATION_MESSAGE'];
            this.noEntitiesErrorMessage = translateValues['FLOW_MONITORING.NO_ENTITIES_NOTIFICATION_MESSAGE'];
            this.dismissText = translateValues['COMMON.DISMISS_TEXT'];
            this.flowMonitoringExportSuccessMessage =
                translateValues['FLOW_MONITORING.EXPORT_REQUEST_SUCCESS_NOTIFICATION_MESSAGE'];
            this.flowMonitoringExportFailureMessage =
                translateValues['FLOW_MONITORING.EXPORT_REQUEST_FAILURE_NOTIFICATION_MESSAAGE'];
            this.flowMonitoringExportNoDataAvailableMessage =
                translateValues['FLOW_MONITORING.EXPORT_NO_DATA_AVAILABLE_TOAST_MESSAGE'];
            this.flowMonitoringExportTitle = translateValues['FLOW_MONITORING.TITLE'];
        });
    }

    public enableFlowMonitoringExport(): boolean {
        return !this.selectedEntities.length || !this.selectedSections.length;
    }

    public configurationsOpenSaveDialog() {

        this.updateFlowMonitoringObject();

        const timeSpanSetting = this.dateService.getTimespanSetting(this.startDate, this.endDate);

        this.previousSearchLocs = [];

        if (
            this.searchModeEnabled &&
            this.initialLocationsList.some((location) => location.isChecked) &&
            this.locations.length
        ) {
            const retainedLocations = this.initialLocationsList.filter(
                (location) => location.isChecked && !this.locations.includes(location),
            );

            this.previousSearchLocs = retainedLocations.filter(loc => loc.isChecked).map(loc => loc.id);
        }

        const settings = {
            cid: this.customerId,
            lids: this.locationType === REPORT_TYPE.LOCATION ? [...this.locations.filter(loc => loc.isChecked).map(loc => loc.id), ...this.previousSearchLocs] : null,
            locationGroupId: this.locationType !== REPORT_TYPE.LOCATION ? this.flowMonitoringExport.locationGroupId : null,
            entityIds: this.flowMonitoringExport.entityIds,
            timeSpanNumeric: timeSpanSetting,
            includeUptime: this.flowMonitoringExport.includeUptime,
            sections: this.flowMonitoringExport.sections,
            groupBy: this.flowMonitoringExport.groupBy,
            SummarizeIntervalScattergraph: this.flowMonitoringExport.SummarizeIntervalScattergraph,
            SummarizeIntervalHydrograph: this.flowMonitoringExport.SummarizeIntervalHydrograph,
            SummarizeIntervalCommentary: this.flowMonitoringExport.SummarizeIntervalCommentary,
            SummarizeIntervalTabular: this.flowMonitoringExport.SummarizeIntervalTabular,
            reportTemplate: this.flowMonitoringExport.reportTemplate,
            scattergraphIsoQ: this.flowMonitoringExport.ScattergraphIsoQ,
            scattergraphFroude: this.flowMonitoringExport.ScattergraphFroude,
            scattergraphPipeOverlay: this.flowMonitoringExport.ScattergraphPipeOverlay,
            includeInactiveLocations: this.shouldIncludeInactiveLocations
        };

        const id = this.conf && this.conf.id ? this.conf.id : null;

        const configBody: FlowMonitoringConfigRequest = {
            configName: this.conf ? this.conf.configName : null,
            settings: settings
        }

        if(id) {
            configBody.id = id;
        }

        this.dialog.open(FmrSaveConfigurationDialogComponent, {
            disableClose: true,
            data: {
                name: this.conf ? this.conf.configName : null,
                body: configBody,
                customerId: this.customerId
            }
        }).afterClosed().subscribe((res: FmrSaveConfigurationDialogComponentResult) => {
            if(res.button === FmrSaveConfigurationDialogComponentButtons.OK) {
                this.getRecentConfigs();
                this.conf = configBody;

                this.conf.id = res.id;
            }
        });
    }

    private getRecentConfigs() {
        this.flowMonitoringService.configurationsRecentGet(this.customerId).subscribe((confs) => {
            this.recentConfigs = confs;
        });
    }

    public configurationsOpenLoadDialog() {
        this.dialog.open(FmrOpenConfigurationDialogComponent, {
            disableClose: true,
            data: {
                customerId: this.customerId
            }
        }).afterClosed().subscribe((res: FmrOpenConfigurationDialogComponentResult) => {
            if(res && res.deletedConfigurationIds && res.deletedConfigurationIds.length > 0) {
                if(res.deletedConfigurationIds.includes(this.conf.id)) {
                    this.conf = null;
                }
            }
            if(res && res.configuration) {
                const conf: FlowMonitoringConfigRequest = {
                    configName: res.configuration.name,
                    settings: res.configuration.settings,
                    id: res.configuration.id
                }
                this.applyConfig(conf);
            }

            this.getRecentConfigs();
        });
    }

    public loadRecentConfig(id: string) {
        const recentConfig = this.recentConfigs.find(conf => conf.id === id);

        if(!recentConfig) {
            // UNABLE TO LOAD CONF
            return;
        }
        const config: FlowMonitoringConfigRequest = {
            configName: recentConfig.name,
            settings: recentConfig.settings,
            id: recentConfig.id
        }
        this.applyConfig(config);
    }

    private applyConfig(config: FlowMonitoringConfigRequest) {
        this.conf = config;
        const settings = config?.settings;

        if(!settings) {
            return;
        }

        this.ScattergraphFroude = settings.scattergraphFroude;
        this.ScattergraphIsoQ = settings.scattergraphIsoQ;
        this.ScattergraphPipeOverlay = settings.scattergraphPipeOverlay;
        const reloadLocations = this.shouldIncludeInactiveLocations !== settings.includeInactiveLocations;
        this.shouldIncludeInactiveLocations = settings.includeInactiveLocations;

        if(reloadLocations) {
            const locationSubscription = this.loadLocationsSubscription().subscribe(
                () => this.applyConfigOnLocations(config)
            );

            this.subscription.push(locationSubscription);
        } else {
            this.applyConfigOnLocations(config);
        }
    }

    private applyConfigOnLocations(config: FlowMonitoringConfigRequest) {
        const settings = config?.settings;

        this.flowMonitoringExport.groupBy = settings.groupBy;
        this.flowMonitoringExport.reportTemplate = settings.reportTemplate;
        this.flowMonitoringExport.includeUptime = settings.includeUptime;

        this.flowMonitoringExport.SummarizeIntervalHydrograph = settings.SummarizeIntervalHydrograph;
        this.flowMonitoringExport.SummarizeIntervalScattergraph = settings.SummarizeIntervalScattergraph;
        this.flowMonitoringExport.SummarizeIntervalTabular = settings.SummarizeIntervalTabular;
        this.flowMonitoringExport.SummarizeIntervalCommentary = settings.SummarizeIntervalCommentary;

        const isReportTypeLocations = settings.locationGroupId === null || (settings.lids && settings.lids.length);

        this.locationType = isReportTypeLocations ? REPORT_TYPE.LOCATION : REPORT_TYPE.LOCATIONGROUP;

        if(isReportTypeLocations) {
            this.flowMonitoringExport.locationGroupId = null;

            if(settings.lids && settings.lids.length) {
                this.locations.forEach(l => l.isChecked = settings.lids.includes(l.id));
                this.onLocationChange();
            }
            else {
                this.locations.forEach(l => l.isChecked = false);
            }
        } else {
            this.locations.forEach(l => l.isChecked = false);
            const group = this.locationGroupList.find(lg => lg.id === settings.locationGroupId);
            this.handleSelectedLocationsGroup([group]);
            this.preSelectedLocationGroup = group;
        }

        this.entities.forEach(e => e.isChecked = settings.entityIds.includes(e.id))
        this.selectedEntities = this.entities.filter(x => x.isChecked);
        this.selectedEntitiesIds = settings.entityIds;
        this.flowMonitoringExport.entityIds = settings.entityIds;

        this.sections.forEach(s => s.isChecked = settings.sections.includes(s.id))
        this.selectedSections = this.sections.filter(x => settings.sections.includes(x.id) && x.id !== DTR_SAME_AVERAGING_AS_HYDROGRAPHS);

        this.showScattergraphFilters = this.selectedSections.some((v) => v.name === FlowMonitorExportSectionTypes.scattergraph);

        if(config.settings.timeSpanNumeric !== undefined && config.settings.timeSpanNumeric !== null) {
            const newDates = this.dateService.setTimespanSetting(config.settings.timeSpanNumeric);
            if(newDates.startDate) this.startDate = newDates.startDate;
            if(newDates.endDate) this.endDate = newDates.endDate;
        } else {
            this.startDate = new Date(config.settings.startTime);
            this.endDate = new Date(config.settings.endTime);
        }

        this.updateFlowMonitoringObject();

        // Needed for locationType change
        this.uiUtilsService.safeChangeDetection(this.cdr);

        this.flowMonitoringService.configurationsRecentPost(this.customerId, config.id).subscribe(
            () => {
                this.getRecentConfigs();
            }
        )
    }
}
