import { Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AxisTickStrategies, ChartXY, ColorHEX, emptyFill, emptyLine, emptyTick, lightningChart, SolidFill, SolveResultSegmentSeries, SolveResultXY, Themes } from '@lightningchart/lcjs';
import { environment } from 'app/environments/environment';
import { DEPTH_ENTITY, RAW_VELOCITY_ENTITY, VELOCITY_ENTITY } from 'app/shared/constant';
import { BasicSeriesData } from 'app/shared/models/hydrographNEW';
import { BpTooltip } from '../bp-tooltip';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { Subscription } from 'rxjs';
import { BP_GRAPH_MAX_INTERVAL_OFFSET, BP_GRAPH_START_INTERVAL, BP_SCATTER_POINT_SIZE, BP_SCATTERGRAPH_DEFAULT_COLOR, BP_SCATTERGRAPH_LAST_DAY_COLOR, GRAPH_WHITE_COLOR_TRANSPARENT, ICanUnfreeze, unfreezeGraph } from 'app/shared/models/blockage-prediction';

@Component({
    selector: 'app-bp-scattergraph',
    template: '<div id="container" (mouseover)="unfreeze()" class="chart-container"></div>',
    styles: [`.chart-container {
    width: 100%;
    height: 100%;
    min-height: 400px;
}`]
})
export class BpScattergraphComponent implements OnInit, OnDestroy, ICanUnfreeze {
    @Input() seriesData: BasicSeriesData[] = [];
    @Input() startTs: number;
    @Input() endTs: number;
    @Input() invertScatter: boolean;

    chart: ChartXY;

    private subscriptions: Subscription[] = [];
    private isDarkMode: boolean;
    private tooltip: BpTooltip;
    isUnfrozen = false;

    constructor(private elementRef: ElementRef, private statusCodeService: StatusCodeService) { }

    ngOnInit() {
        this.tooltip = new BpTooltip(this.elementRef, this.seriesData, this.statusCodeService);

        this.subscriptions.push(
            this.statusCodeService.userInfoThemeBS.subscribe((isDark: boolean) => {
                this.isDarkMode = isDark;

                this.createChart();
            })
        )
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['seriesData'] && !changes['seriesData'].firstChange) {
            if (this.chart) {
                this.chart.dispose();
            }
            this.createChart();
        }
    }

    createChart() {
        if (this.chart) {
            this.chart.dispose();
        }

        this.chart = lightningChart({
            license: environment.lcLicenseNumber,
            resourcesBaseUrl: 'assets/lc'
        })
            .ChartXY({
                container: this.elementRef.nativeElement.querySelector('#container'),
                theme: this.isDarkMode ? Themes.darkGold : Themes.light,
            }).setTitle('')
            .setBackgroundFillStyle(new SolidFill({ color: GRAPH_WHITE_COLOR_TRANSPARENT }))
            .setSeriesBackgroundFillStyle(new SolidFill({ color: GRAPH_WHITE_COLOR_TRANSPARENT }))
            .setBackgroundStrokeStyle(emptyLine)
            .setCursorMode(null)

        const depthSeries = this.seriesData.find(s => s.entityId === DEPTH_ENTITY);
        const velocitySeries = this.getVelocityEntity(this.seriesData);

        this.tooltip.setChart(this.chart);

        this.chart.onSeriesBackgroundMouseMove((chart, event) => {
            this.handleMouseMove(event);
        });

        this.chart.onSeriesBackgroundMouseLeave(() => {
            this.tooltip.hide();
        });


        if (!depthSeries || !velocitySeries) {
            return;
        }

        const velocityLabel = `${velocitySeries.entityName} (${velocitySeries.unitOfMeasure})`;
        const depthLabel = `${depthSeries.entityName} (${depthSeries.unitOfMeasure})`;

        const velocityMax = Math.max(...velocitySeries.data.map(v => v.y));
        const depthMax = Math.max(...depthSeries.data.map(v => v.y));

        const xAxisMax = this.invertScatter ? (depthMax * BP_GRAPH_MAX_INTERVAL_OFFSET) : (velocityMax * BP_GRAPH_MAX_INTERVAL_OFFSET);
        const yAxisMax = this.invertScatter ? (velocityMax * BP_GRAPH_MAX_INTERVAL_OFFSET) : (depthMax * BP_GRAPH_MAX_INTERVAL_OFFSET);

        this.chart.getDefaultAxisX()
            .setTitle(this.getAxisName(depthLabel, velocityLabel, true))
            .setTickStrategy(AxisTickStrategies.Numeric, (tickStrategy) =>
                tickStrategy.setMinorTickStyle(emptyTick)
            ).setInterval({ start: BP_GRAPH_START_INTERVAL, end: xAxisMax }).setIntervalRestrictions((state) => ({
                startMin: BP_GRAPH_START_INTERVAL,
                endMax: xAxisMax
            }));;

        this.chart.getDefaultAxisY()
            .setTitle(this.getAxisName(depthLabel, velocityLabel, false))
            .setTickStrategy(AxisTickStrategies.Numeric, (tickStrategy) =>
                tickStrategy.setMinorTickStyle(emptyTick)
            ).setInterval({ start: BP_GRAPH_START_INTERVAL, end: yAxisMax }).setIntervalRestrictions((state) => ({
                startMin: BP_GRAPH_START_INTERVAL,
                endMax: yAxisMax
            }));;

        const { regularPoints, lastDayPoints } = this.processData(depthSeries, velocitySeries);

        const regularSeries = this.chart.addPointLineAreaSeries()
            .setAreaFillStyle(emptyFill)
            .setStrokeStyle(emptyLine)
            .setPointSize(BP_SCATTER_POINT_SIZE)
            .setPointFillStyle(new SolidFill({ color: ColorHEX(BP_SCATTERGRAPH_DEFAULT_COLOR) }))
            .add(regularPoints);

        regularSeries.setMouseInteractions(true)
            .onMouseMove((serie, event) => this.handleMouseMove(event));

        regularSeries.onMouseLeave(() => this.tooltip.hide());

        const lastDaySeries = this.chart.addPointLineAreaSeries()
            .setAreaFillStyle(emptyFill)
            .setStrokeStyle(emptyLine)
            .setPointSize(BP_SCATTER_POINT_SIZE)
            .setPointFillStyle(new SolidFill({ color: ColorHEX(BP_SCATTERGRAPH_LAST_DAY_COLOR) }))
            .add(lastDayPoints);

        lastDaySeries.setMouseInteractions(true)
            .onMouseMove((serie, event) => this.handleMouseMove(event));

        lastDaySeries.onMouseLeave(() => this.tooltip.hide());
    }

    private processData(depthSeries: BasicSeriesData, velocitySeries: BasicSeriesData) {
        const lastDayStart = new Date(this.endTs).setHours(0, 0, 0, 0);
        const regularPoints: { x: number, y: number }[] = [];
        const lastDayPoints: { x: number, y: number }[] = [];

        const depthMap = new Map(
            depthSeries.data
                .filter(point => !point.ignored)
                .map(point => [point.x, point.correctedY ?? point.y])
        );

        velocitySeries.data
            .forEach(velocityPoint => {
                if (velocityPoint.ignored) {
                    return;
                }

                const depth = depthMap.get(velocityPoint.x);
                if (depth !== undefined) {
                    const velocityValue = velocityPoint.correctedY ?? velocityPoint.y;
                    const point = {
                        x: this.invertScatter ? depth : velocityValue,
                        y: this.invertScatter ? velocityValue : depth
                    };

                    if (velocityPoint.x >= lastDayStart) {
                        lastDayPoints.push(point);
                    } else {
                        regularPoints.push(point);
                    }
                }
            });

        return { regularPoints, lastDayPoints };
    }

    private getVelocityEntity(seriesData: BasicSeriesData[]) {
        const rawvel = seriesData.find(v => v.entityId === RAW_VELOCITY_ENTITY);

        if (rawvel) {
            return rawvel;
        }

        return seriesData.find(v => v.entityId === VELOCITY_ENTITY);
    }

    private getAxisName(depthLabel: string, velocityLabel: string, isXaxis = false) {
        if (this.invertScatter) {
            return isXaxis ? depthLabel : velocityLabel;
        }

        return isXaxis ? velocityLabel : depthLabel;
    }

    private handleMouseMove = (event: MouseEvent) => {
        const nearestDataPoints: SolveResultXY[] = [];

        this.seriesData.forEach((_, index) => {
            const series = this.chart.getSeries()[index];
            if (series) {
                nearestDataPoints.push(series.solveNearest({
                    clientX: event.clientX,
                    clientY: event.clientY
                }));
            }
        });

        this.tooltip.showScatterTooltip(event, nearestDataPoints);
    }

    // TODO: Temporary solution to solve graph freezing problem on initial load,
    // issue is that SOMETIMES graphs are not reflecting to any mouse interaction on initial load
    unfreeze() {
        unfreezeGraph(this, this.elementRef);
    }

    ngOnDestroy() {
        if (this.chart) {
            this.chart.dispose();
            this.chart = undefined;
        }

        this.subscriptions.forEach(v => v.unsubscribe());
        this.subscriptions = [];

        this.tooltip?.dispose();
    }
}
