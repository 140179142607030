import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacySelectionListChange as MatSelectionListChange } from '@angular/material/legacy-list';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UsersService } from 'app/pages/admin/users.service';
import { customerQueryParam } from 'app/shared/models/customer';
import { LocationGroup } from 'app/shared/models/location-group';
import { RainLocation } from 'app/shared/models/locations';
import { Selectable } from 'app/shared/models/selectable';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { Subscription } from 'rxjs';
import { filter, first, switchMap, skip, map, distinctUntilChanged } from 'rxjs/operators'
import { EXPORT_TYPE_COLLECTION, LocationGroupSelectable, RainfallSearchTypeValue } from '../site-commentary-report/site-commentary.model';
import { DailyRainfallReportService } from './daily-rainfall-report.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { DatePipe } from '@angular/common';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { DailyRainfallReportModel, DailyRainfallSettings } from './daily-rainfall-report.models';
import { RAIN, RAINFINAL, RAIN_ENTITY, RAIN_FINAL_ENTITY } from 'app/shared/constant';
import { QUICK_DATE_RANGES } from 'app/shared/models/view-data';
import { DailyQuickSpan, timespanFromAPI, timespanToAPI } from 'app/shared/models/daily-summary';

const DATE_COL_NAME = 'Date';
const INCHES = 'in';
const MILLIMETERS = 'mm';

const RAIN_ENTITIES = [
    { id: RAIN_ENTITY, name: RAIN.toUpperCase() },
    { id: RAIN_FINAL_ENTITY, name: RAINFINAL.toUpperCase() }
];

@Component({
    selector: 'app-daily-rainfall-report',
    templateUrl: './daily-rainfall-report.component.html',
    styleUrls: ['./daily-rainfall-report.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DailyRainfallReportComponent implements OnInit, OnDestroy {
    public isLoading = false;
    private subscription = new Subscription();
    public locationSearch = new FormControl('');

    public locationGroupsUnavailable: boolean;
    public selectAllLocations: boolean;
    private customerId: number;

    public locationTypeCollection: Array<{ name: string, value: string }> = EXPORT_TYPE_COLLECTION;
    public locationGroupList: Array<LocationGroupSelectable> = [];
    public filteredLocations: Selectable[] = [];
    public locations: Selectable[] = [];
    public locationType = RainfallSearchTypeValue.location;
    public shouldIncludeInactiveLocations = false;
    public locationsUnavailable: boolean;
    public minimumLocationError: boolean;

    private selectedLocationIds: number[] = [];
    private selectedLocationGroupId: number = 0;

    public startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, 0, 0, 0);
    public endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59);
    public dateFormat: string;

    public reportData: string[][] = [];
    public reportTotals: string[] = [];
    public reportHeaders: string[] = [];

    public noDataError = false;
    public today = new Date(new Date().setHours(23, 59, 59));

    public selectedEntityId: number;
    public rainEntities = RAIN_ENTITIES;

    public rainUnit: string;
    public selectedTimespan: QUICK_DATE_RANGES;
    constructor(private locationGroupService: LocationGroupService, private activatedRoute: ActivatedRoute,
            private uiUtilsService: UiUtilsService, private dateutilService: DateutilService, private datePipe: DatePipe,
            private cdr: ChangeDetectorRef, private usersService: UsersService, private dailyRainfallReportService: DailyRainfallReportService) { }

    ngOnInit(): void {
        const hasRawDataPermission = this.usersService.isRawDataEditingAllowed.getValue();
        this.selectedEntityId = hasRawDataPermission ? RAIN_ENTITY : RAIN_FINAL_ENTITY;
        this.minimumLocationError = false;
        const metricSubs = this.dateutilService.isCustomerUnitsMetric.subscribe((isMetric) => {
            this.rainUnit = isMetric ? MILLIMETERS : INCHES;
        });
        this.subscription.add(metricSubs);

        const includeRainLocsSubs = this.usersService.userSettings.pipe(
            filter(v => !!v),
            skip(1),
            map(v => v.includeRainLocations),
            distinctUntilChanged()
        ).subscribe(() => {
            this.getLocationGroups();
            this.getLocations(true);
        });

        this.subscription.add(includeRainLocsSubs);

        const dateFormatSubs = this.dateutilService.dateFormat.subscribe(() => {
            // Getting customer dateformat from dateUtil Service
            this.dateFormat = this.dateutilService.getFormat();
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });

        this.subscription.add(dateFormatSubs);

        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            const newCid = Number(params.get(customerQueryParam));

            if (newCid === this.customerId) {
                return;
            }

            this.loadUserSettings();
            this.customerId = Number(params.get(customerQueryParam));

            this.getLocations();
            this.getLocationGroups();

            this.reportData = [];
            this.reportHeaders = [];
            this.reportTotals = [];
            this.selectAllLocations = false;

            this.uiUtilsService.safeChangeDetection(this.cdr);
        });

        this.subscription.add(this.locationSearch.valueChanges.subscribe((searchValue: string) => this.filterLocations(searchValue)));
    }

    public downloadReport() {
        if (!this.reportData || !this.reportData.length) return;

        const selectedEntity = this.rainEntities.find(v => v.id === this.selectedEntityId);
        const options = {
            showLabels: true,
            headers: this.reportHeaders.map(v => `"${v}"`),
            title: `Daily Rainfall ${selectedEntity.name} (${this.rainUnit}) ${this.datePipe.transform(this.startDate, this.dateFormat)} - ${this.datePipe.transform(this.endDate, this.dateFormat)}`,
            showTitle: true,
        };

        new AngularCsv([...this.reportData, this.reportTotals], 'Daily Rainfall', options);
    }

    public handleLocationTypeChange(event: MatSelectChange) {
        if (event.value === RainfallSearchTypeValue.locGroup) {
            this.minimumLocationError = false;
            this.locationGroupList = this.locationGroupList.map(v => ({ ...v, isChecked: false }));
        }
    }

    public entityChange(event: MatSelectChange) {
        this.generateReport();
    }

    public handleSelectedLocationsGroup(event: LocationGroupSelectable[]) {
        if (!event || !event.length) return;

        this.selectedLocationGroupId = event[0].id;
        this.selectedLocationIds = event[0].locations.map(v => v.id);
        this.generateReport();
    }

    public onLocationChecked() {
        this.selectedLocationIds = this.locations.filter(v => v.isChecked).map(v => v.id);

        if (!this.selectedLocationIds.length) {
            this.reportData = [];
            this.minimumLocationError = true;
            this.reportHeaders = [];
            this.reportTotals = [];

            return;
        }
        this.minimumLocationError = false;
        this.generateReport();
    }

    public onSelectAllLocations(event: MatCheckboxChange) {
        const currentValue = event.checked;
        this.selectAllLocations = currentValue;

        if(!this.selectAllLocations)
        {
            this.minimumLocationError = true;
        }
        this.minimumLocationError = false;
        this.filteredLocations.forEach(v => v.isChecked = currentValue);
        this.onLocationChecked();
    }

    public onDateRangeChange(event: SimpleChanges) {
        if (this.startDate.getTime() === event.startDate.currentValue.getTime() && this.endDate.getTime() === event.endDate.currentValue.getTime()) {
            return;
        }

        this.startDate = event.startDate.currentValue;
        this.endDate = event.endDate.currentValue;

        this.saveUserSettings();
        this.generateReport();
    }

    private generateReport() {
        if (!this.selectedLocationIds || !this.selectedLocationIds.length) {
            return;
        }

        this.isLoading = true;
        this.noDataError = false;

        const entityIds = [this.selectedEntityId];
        const selectedLocGroupId = this.locationType === RainfallSearchTypeValue.location ? 0 : this.selectedLocationGroupId;
        this.dailyRainfallReportService.getReportData(this.customerId, selectedLocGroupId, this.selectedLocationIds, this.startDate, this.endDate, this.shouldIncludeInactiveLocations, entityIds)
            .subscribe((reportData: DailyRainfallReportModel) => {
            this.isLoading = false;
            if (!reportData) {
                this.noDataError = true;
                this.reportData = [];
                this.reportHeaders = [];
                this.reportTotals = [];
                this.uiUtilsService.safeChangeDetection(this.cdr);
                return;
            };

            const { data, headers, totals } = reportData;

            this.reportHeaders = headers.map(v => v.name);

            this.reportData = this.formatReportValue(data)
            this.reportTotals = totals.map(v => v.value);

            this.uiUtilsService.safeChangeDetection(this.cdr);
        }, () => {
            this.isLoading = false;
            this.noDataError = true;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
    }

    private formatReportValue(data: { value?: string }[][]) {
        const isMetric = this.dateutilService.isCustomerUnitsMetric.getValue();
        const decimalUnits = isMetric ? 1 : 2;

        return data.map((row) => row.map((v, i) => {
            if (isNaN(Number(v.value))) {
                return v.value;
            }

            return Number(v.value).toFixed(decimalUnits);
        }));
    }

    private filterLocations(searchValue: string, keepSelectedLocs = false) {
        this.filteredLocations = this.locations.filter(v => v.name.toLowerCase().includes(searchValue.toLowerCase()));

        if (keepSelectedLocs && this.selectedLocationIds.length) {
            this.filteredLocations = this.filteredLocations.map(v => this.selectedLocationIds.includes(v.id) ? ({ ...v, isChecked: true }) : v);
        }
    }

    public getLocations(keepSelectedLocs = false) {
        this.filteredLocations = [];
        this.locations = [];

        if(this.locationType === RainfallSearchTypeValue.locGroup)
        {
            this.generateReport();
        }
        // request uses user settings, so we need to make sure that user settings are loaded
        this.usersService.userSettings.pipe(
                filter(v => !!v),
                first(),
                switchMap(() => this.usersService.getRainLocations(this.customerId,this.shouldIncludeInactiveLocations, undefined, true))
            ).subscribe((locations: RainLocation[]) => {

            if (locations && locations.length) {

                this.locationsUnavailable = false;
                this.locations = locations.map(v => ({ id: v.locationId, name: v.locationName, isChecked: false }));
                this.minimumLocationError = false;
                this.filterLocations(this.locationSearch.value, keepSelectedLocs);

                if (keepSelectedLocs && this.reportHeaders.length) {
                    // in case selected location is no more in the list
                    const toRemoveIndexes = this.reportHeaders.map((v, i) => (i !== 0 && !this.locations.find(i => i.name === v)) ? i : null).filter(v => v !== null);
                    const toRemoveLocNames = toRemoveIndexes.map(v => this.reportHeaders[v]);

                    if (toRemoveIndexes.length) {
                        // also need to filter locations from selected ids
                        const toUnselectIds = this.locations.filter(v => toRemoveLocNames.includes(v.name)).map(v => v.id);
                        this.selectedLocationIds = this.selectedLocationIds.filter(v => !toUnselectIds.includes(v));

                        this.reportHeaders = this.reportHeaders.filter((v, i) => !toRemoveIndexes.includes(i));
                        this.reportData = this.reportData.map(v => v.filter((x, i) => !toRemoveIndexes.includes(i)));
                        this.reportTotals = this.reportTotals.filter((v, i) => !toRemoveIndexes.includes(i));

                        // if all selected locations were removed
                        if (this.reportHeaders.length === 1 && this.reportHeaders[0] === DATE_COL_NAME) {
                            this.reportHeaders = [];
                            this.reportData = [];
                            this.reportTotals = [];
                        }
                    }
                }
            }
            else{
                this.locationsUnavailable = true;
            }

            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
    }

    private getLocationGroups() {
        this.locationGroupList = [];
        const locationGroupSubscription = this.locationGroupService.getRainLocationGroups(this.customerId).subscribe(
            (locationGroupList) => {
                if (locationGroupList && locationGroupList.locationGroups && locationGroupList.locationGroups.length) {
                    this.locationGroupsUnavailable = false;
                    locationGroupList.locationGroups.forEach((locationGroup: LocationGroup) => {
                        this.locationGroupList.push({
                            id: locationGroup.locationGroupID,
                            name: locationGroup.name,
                            isChecked: false,
                            locations: locationGroup.locations.map((v) => ({ id: v['locationID'], name: v['name'] }))
                        });
                    });
                } else {
                    this.locationGroupsUnavailable = true;
                }
            }
        );
        this.subscription.add(locationGroupSubscription);
    }

    private saveUserSettings() {
        const quickSpan = timespanToAPI(this.selectedTimespan);
        const settings: DailyRainfallSettings = { quickSpan: quickSpan, customStart: this.startDate, customStop: this.endDate };

        this.dailyRainfallReportService.saveSettings(settings).subscribe((ignoreResult) => { });
    }

    private loadUserSettings() {
        this.dailyRainfallReportService.loadSettings().subscribe((settings: DailyRainfallSettings) => {
            if (settings.quickSpan && settings.quickSpan !== DailyQuickSpan.Custom) {
                const timeSpan = timespanFromAPI(settings.quickSpan);
                if (timeSpan !== this.selectedTimespan) {
                    this.selectedTimespan = timeSpan;
                }
            }
            else if (settings.quickSpan && settings.quickSpan === DailyQuickSpan.Custom) {
                if (settings.customStart) {
                    this.startDate = new Date(settings.customStart);
                }
                if (settings.customStop) {
                    this.endDate = new Date(settings.customStop);
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
